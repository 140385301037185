import React, { Component } from "react"
import classNames from "classnames"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import DropdownWrap from "components/shared/dropdown/DropdownWrap"
import DropdownStyled from "components/shared/dropdown/DropdownStyled"
import DropdownToggle from "components/shared/dropdown/DropdownToggle"
import DropdownMenu from "components/shared/dropdown/DropdownMenu"
import DropdownItem from "components/shared/dropdown/DropdownItem"

const FilterCount = styled.span`
    background: ${props => props.theme.colors.cyan};
    color: white;
    padding: 0 .25rem;
    display: inline-block;
`

class ListingFilterDropdown extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false
        }

        this.minDate = new Date(this.props.startDate)
        this.maxDate = new Date(this.props.endDate)

        // console.log(this.props.startDate)
        // console.log(this.props.endDate)

        this.months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

        this.toggle = this.toggle.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside, false)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside, false)
    }

    handleClickOutside(e) {
        if (typeof window !== "undefined" && window.outerWidth > 991) {
            if (this.node && !this.node.contains(e.target)) {
                this.setState({
                    isOpen: false
                })
            }
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        const { type, last, filter } = this.props
        return(         
            <DropdownWrap ref={node => this.node = node} last={last}>
                <DropdownStyled>
                    <DropdownToggle
                        onClick={this.toggle}
                        className={classNames({ active: this.state.isOpen })}
                        imgIcon={filter === "selectedDateFilters" || filter === "selectedSessionFilters"}
                    >
                        {this.props.filterText} 
                        <div className="d-flex">
                            {this.props.selectedFilters && this.props.selectedFilters.length > 0 &&
                                <FilterCount>{this.props.selectedFilters.length}</FilterCount>
                            }
                            <div>
                                <FontAwesomeIcon className="icon" icon={faAngleDown} />
                            </div>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu 
                        className={classNames({ active: this.state.isOpen })} 
                        as={type === "default" ? "ul" : "div"}
                        type={type}
                    >
                        {this.props.filters && this.props.filters.map(filter => {
                            if (filter.databaseId) {
                                return(
                                    <DropdownItem  
                                        key={filter.databaseId} 
                                        className={classNames({
                                            active: this.props.selectedFilters.includes(filter.databaseId) 
                                        })}
                                        onClick={() => this.props.setFilter(filter.databaseId, this.props.filters.name, this.props.filter)}
                                    >
                                        <span className="checkbox"></span>
                                        {filter.acfCategoryIcon && filter.acfCategoryIcon.icon &&
                                            <GatsbyImage 
                                                image={filter.acfCategoryIcon.icon.localFile.childImageSharp.gatsbyImageData}
                                                alt={filter.acfCategoryIcon.icon.altText}
                                            />
                                        }
                                        <span>{filter.name}</span>
                                    </DropdownItem>
                                )
                            } return ""
                        })}
                    </DropdownMenu>
                </DropdownStyled>
            </DropdownWrap>
        )
    }
}

ListingFilterDropdown.defaultProps = {
    type: "default",
    inputType: "checkbox"
}


export default ListingFilterDropdown