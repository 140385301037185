import React, {useEffect, useRef, useState} from "react"
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import MasonryWall from "components/shared/MasonryWall"
import GalleryGridItem from "./GalleryGridItem"
import Button from "components/shared/Button"

export default function GalleryGrid({images}) {
	const [paginationLimit, setPaginationLimit] = useState(20)
	const [photoIndex, setPhotoIndex] = useState(-1)
	let sizes = {
		default: 4,
		992: 3,
		768: 2
	}
	const galleryImages = images
	const postsPerPage = 20

	const viewMoreImages = () => {
		setPaginationLimit(paginationLimit + postsPerPage)
	}

	const slidesArray = galleryImages?.map(lightboxImage => {
		return(
			{
				type: "image",
				src: lightboxImage.localFile.publicURL,
				title: lightboxImage.caption,
				description: lightboxImage.caption,
			}
		)
	})

	let postCount = 0

	return(
		<div>
			<MasonryWall breakpoints={sizes}>
				{galleryImages.map((image, i) => {
					if (postCount < paginationLimit) {
						postCount++
						if (image && image.localFile) {
							return (
								<div
									role="button" aria-pressed="false"
									key={i}
									tabIndex={i}
									onClick={() => setPhotoIndex(i)}
								>
									<GalleryGridItem
										key={i}
										thumbnail={image.localFile.resize.gatsbyImageData}
										largeImage={image.localFile.publicURL}
										altText={image.altText}
									/>
								</div>
							)
						}
					}
				})}
			</MasonryWall>
			{paginationLimit < galleryImages.length &&
				<div className="pt-3">
					<Button as="button" onClick={viewMoreImages} color="black">
						<span>View more images</span>
					</Button>
				</div>
			}


			<Lightbox
				open={photoIndex > -1}
				styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
				close={() => setPhotoIndex(-1)}
				slides={slidesArray}
				index={photoIndex}
				render={{
					slide: ({ slide }) => {
						return(
							<Item slide={slide} />
						)
					}
				}}
			/>
		</div>
	)
}

const Item = ({slide}) => {

    const refBox = useRef()
    const refImg = useRef()
    const [top, setTop] = useState(0)
    const [opacity, setOpacity] = useState(0)

    useEffect(() => {
        function calcPosition() {
            if (refBox?.current && refImg?.current) {
                setTop(((refBox.current.offsetHeight - refImg.current.offsetHeight) / 2) + (refImg.current.offsetHeight + 10))
            } else {
                setTop("100%")
            }
            
            setOpacity(1)
        }

        refImg.current.addEventListener('load', () => {
            calcPosition()
        })

        window.addEventListener('resize', calcPosition)

        return () => {
            window.removeEventListener('resize', calcPosition)
        }
    }, [])

    return(
        <div style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none"
        }}>
            <div 
            ref={refBox}
            style={{
                height: "80vh",
                position: "relative",
                display: "flex",
                alignItems: "center"
            }}>
                <img 
                    src={slide.src} 
                    alt="" 
                    loading="lazy" 
                    ref={refImg}
                    style={{
                        maxHeight: "100%"
                    }} 
                />
                <div dangerouslySetInnerHTML={{ __html: slide.description }} style={{
                    position: "absolute",
                    top: top,
                    left: 0,
                    width: "100%",
                    color: "white",
                    opacity: opacity
                }} />
            </div>
        </div>
    )
}