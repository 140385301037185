import React from "react"
import { Row, Col } from "reactstrap"
import styled, { css } from "styled-components"
import ContainerMax from "components/shared/ContainerMax"
import Text from "components/shared/Text"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import Button from "components/shared/Button"
import { GatsbyImage } from "gatsby-plugin-image"

const Title = styled(Text)`
  font-size: ${props => props.theme.font.h1.size};
`

const FilterButton = styled.button`
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: none;
  width: 100%;
  background: white;
  text-transform: uppercase;
  height: 100%;
  font-family: ${props => props.theme.font.family.condensed};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size.lg};
  color: black;
  line-height: 1.1;
  padding: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${props => props.$active && css`
    box-shadow: 0px 0px 22px 0px rgba(0, 0, 137, 0.80);
  `}

  span {
    display: block;
    padding: .75rem;
  }

  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;

    ${props => props.$active && css`
      border: 5px solid ${props => props.theme.colors.secondary};
    `}
  }

  &:hover {
    box-shadow: 0px 0px 22px 0px rgba(0, 0, 137, 0.80);
  }

`

export default function ActivityPlannerStep({ 
  title, 
  filters, 
  activeStep, 
  activeFilters, 
  setActiveStep,
  setFilter
}) {
  const toggleActiveFilter = (node) => {
    // set the filter
    setFilter(node)
  }

  const changeStep = (step) => {
    if (activeFilters?.length > 0 || step < activeStep) {
      setActiveStep(step)
    }
  }

  return(
    <ContainerMax maxWidth="1300" className="py-4 px-0">
      {title && (
        <Title as="h2" title className="text-center pb-5">{title}</Title>
      )}

      <Row className="justify-content-center">
        {filters?.edges.map(({node}, i) => {
          return(
            <Col xs={6} md={4} lg={3} className="mb-4" key={`filterButton${i}`}>
              <FilterButton onClick={() => toggleActiveFilter(node)} $active={activeFilters?.includes(node.databaseId)}>
                {node.acfActivityPlannerCategory.image && (
                  <GatsbyImage image={node.acfActivityPlannerCategory.image.localFile.childImageSharp.gatsbyImageData} />
                )}
                <span>{node.name}</span>
              </FilterButton>
            </Col>
          )
        })}
      </Row>

      <Row className="justify-content-center py-4">
        {activeStep !== 1 && (
          <Col xs="auto">
            <Button onClick={() => changeStep(activeStep - 1)}>
              <FontAwesomeIcon icon={faAngleLeft} /> Back
            </Button>
          </Col>
        )}
        <Col xs="auto">
          <Button onClick={() => changeStep(activeStep + 1)} color={activeFilters?.length > 0 ? "secondary" : "disabled"}>
            Next <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </Col>
      </Row>
    </ContainerMax>
  )
}