import React from "react"
import ContainerMax from "components/shared/ContainerMax"
import Breadcrumbs from "components/shared/Breadcrumbs"

const BreadcrumbsExport = (props) => {
  return(
    <ContainerMax maxWidth="1600" className="pb-4">
      <Breadcrumbs breadcrumbs={props.block.breadcrumbs} />
    </ContainerMax>
  )
}

export default BreadcrumbsExport