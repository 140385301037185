import styled from "styled-components"

const DropdownItem = styled.li`
    position: relative;
    padding: .3rem .75rem .3rem 2.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: ${props => props.theme.font.size.sm};

    button {
        background-color: transparent;
        border: 0;
        padding: 0;
        text-transform: uppercase;
    }

    .checkbox {
        width: 14px;
        height: 14px;
        display: inline-block;
        position: absolute;
        left: .75rem;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid #9D9D9D;
        cursor: pointer;
        border-radius: 50%;

        &:before {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .gatsby-image-wrapper {
        margin-right: .5rem;
    }

    .date {
        color: ${props => props.theme.colors.purple};
    }

    &.active {
        span {
            &:before {
                background-color: ${props => props.theme.colors.neonGreen};
            }
        }
    }
`

export default DropdownItem