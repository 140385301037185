import React, { Component } from "react"
import styled from "styled-components"
import classNames from "classnames"
import queryString from "query-string"
import { media } from "utils/Media"
import ListingFilterDropdown from "./ListingFilterDropdown"
import FilterBar from "components/shared/FilterBar"
import Text from "components/shared/Text"
import Button from "components/shared/Button"
import filter from "images/icon-filters.svg"

const FilterTrigger = styled.button`
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: 0;
    text-align: center;
    font-weight: ${props => props.theme.font.weight.bold};
    margin-bottom: 1.5rem;
    padding: .75rem;

    @media ${media.lg} {
        display: none;
    }
`

class ListingFilterBar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            active: false
        }

        this.triggerFilterOverlay = this.triggerFilterOverlay.bind(this)
        this.setFilter = this.setFilter.bind(this)
    }

    triggerFilterOverlay() {
        this.setState({
            active: !this.state.active
        })
        document.body.classList.toggle('dropdown-filter-active')
    }

    /**
     * set the filter
     * value = databaseId or date
     */
    setFilter = (value, filter, inputType) => {
        // name required as prop if tracking
        // window.analytics.track("Filter click", {
        //     filter: filter === 1 ? this.props.filter1.name : this.props.filter2.name,
        //     filterName: name
        // })

        let newSelectedFilters, databaseId
        const selectedFilters = this.props[filter]

        if (filter === "selectedDateFilters") {
            newSelectedFilters = value
        } else if (inputType === "radio") {
            databaseId = parseInt(value)
            newSelectedFilters = selectedFilters.includes(databaseId) ? [] : [databaseId]
        } else {
            // Add or remove filter from array
            databaseId = parseInt(value)
            newSelectedFilters = selectedFilters.includes(databaseId)
                ? selectedFilters.filter(i => i !== databaseId) // remove item
                : [ ...selectedFilters, databaseId ]; // add item
        }

        /** 
         * Apply filters for url, used so they can link with pre-defined filters
         * */
        // get query string
        const urlString = typeof window !== 'undefined' ? window.location.search : ''

        // parse query string into json
        const parsed = queryString.parse(urlString)

        // set filter with new filters
        parsed[filter] = newSelectedFilters

        // stringify filters to url query string
        const stringified = queryString.stringify(parsed, { arrayFormat: 'comma' });

        // set new url with correct filters
        if (window.history.replaceState) {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + stringified
            window.history.replaceState({ path: newurl }, '', newurl)
        }

        // Update parent component filters so results update
        this.props.setFilters(newSelectedFilters, filter)
    }

    render() {
        const {
            dateFilters,
            sessionFilters,
            ageFilters,
            categoryFilters,
            selectedDateFilters,
            selectedSessionFilters,
            selectedAgeFilters,
            selectedCategoryFilters,
            resetFilters
        } = this.props
        return (
            <>
                <FilterTrigger onClick={this.triggerFilterOverlay}>
                    FILTERS <img src={filter} alt="" className="pl-3" />
                </FilterTrigger>
                <FilterBar className={classNames({
                    active: this.state.active
                })}>

                    <Text as="span" bold className="mr-4 d-none d-xl-inline">FILTER BY</Text>

                    <ListingFilterDropdown
                        filter="selectedDateFilters"
                        filters={dateFilters}
                        selectedFilters={selectedDateFilters}
                        setFilter={this.setFilter}
                        filterText="SELECT DATE"
                        type="date"
                    />

                    <ListingFilterDropdown
                        filter="selectedSessionFilters"
                        filters={sessionFilters}
                        selectedFilters={selectedSessionFilters}
                        setFilter={this.setFilter}
                        filterText="SELECT ENTRY TIME"
                        inputType="radio"
                    />

                    <ListingFilterDropdown
                        filter="selectedAgeFilters"
                        filters={ageFilters}
                        selectedFilters={selectedAgeFilters}
                        setFilter={this.setFilter}
                        filterText="SELECT GROUP TYPE"
                    />

                    <ListingFilterDropdown
                        filter="selectedCategoryFilters"
                        filters={categoryFilters}
                        selectedFilters={selectedCategoryFilters}
                        setFilter={this.setFilter}
                        filterText="SELECT CATEGORIES"
                        last
                    />

                    <div className="d-lg-none d-flex justify-content-between filter-buttons">
                        <Button onClick={resetFilters} color="grey">
                            Clear
                        </Button>
                        <Button color="secondary" onClick={this.triggerFilterOverlay}>
                            Apply
                        </Button>
                    </div>
                </FilterBar>
            </>
        )
    }
}

export default ListingFilterBar