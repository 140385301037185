import React from "react"
import classNames from "classnames"
import styled from "styled-components"
import ContainerMax from "components/shared/ContainerMax"
import { LinkSearchReplace } from "utils/LinkSearchReplace"
import TextBlockStyles from "components/shared/TextBlockStyles"
import Text from "components/shared/Text"
import { media } from 'utils/Media'

const TextAside = styled.section`
    @media ${media.md} {
        display: flex;
        gap: 2rem;
        align-items: flex-start;
    }
`

const TextStyled = styled.div`
    p {
        font-size: ${props => props.theme.font.size.base};
    }
`
const Aside = styled.aside`
    margin-top: 2rem;
    border: solid 3px ${props => props.theme.colors.secondary};
    font-family: ${props => props.theme.font.family.condensed};
    color: ${props => props.theme.colors.secondary};
    text-transform: uppercase;
    font-weight: ${props => props.theme.font.weight.bold};
    padding: 1.5rem;
    font-size: ${props => props.theme.font.size.md};
    border-radius: 4px;
    text-align: center;
    @media ${media.md} {
        margin-top: 0;
        min-width: 200px;
    }
    @media ${media.lg} {
        margin-top: 0;
        min-width: 300px;
        font-size: ${props => props.theme.font.size.lg};
        padding: 1rem .25rem;
    }
    @media ${media.xl} {
        margin-top: 0;
        min-width: 350px;
        font-size: ${props => props.theme.font.size.lg};
        padding: 2rem .25rem;
    }
    
`

const TextWithAside = (props) => {
    let { text, title, aside } = props.block
    if (text) {
        // Wrap iframes with responsive div
        text = text.replace('<iframe', '<div class="embed-responsive embed-responsive-16by9"><iframe')
        text = text.replace('</iframe>', '</iframe></div>')
        let width = '1366'
        if (props.block.width === "narrow") {
            width = '1366';
        }
      

        return (
            <ContainerMax
                maxWidth={width}
                className={classNames({
                    "justify-content-center": true,
                    "py-4 py-xl-5" : props.block.padding === "yes"
                })}
            >
                <TextAside>
                    <div>
                        {title && 
                            <Text as="h2" title className="mb-3">{title}</Text>
                        }
                        <TextBlockStyles>
                            <TextStyled dangerouslySetInnerHTML={{ __html: LinkSearchReplace(text) }} />
                        </TextBlockStyles>
                    </div>
                    {aside && 
                        <Aside>
                            <TextStyled>{aside}</TextStyled>
                        </Aside>
                    }
                </TextAside>
            </ContainerMax>
        )
    } else {
        return ""
    }
}

export default TextWithAside