import React from "react"
import ReactPlayer from "react-player"
import styled from "styled-components"
import { media } from "utils/Media"
import ContainerMax from "components/shared/ContainerMax"
import snowflake from "images/snowflake-video.svg"

const SnowflakeStyled = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    display: none;

    @media ${media.xl} {
        display: block;
    }
`

const VideoBlock = (props) => {
    if (props.block.video !== null) {
        return (
            <div className="position-relative">
                <SnowflakeStyled src={snowflake} alt="" />
                <ContainerMax
                    maxWidth="1300"
                    className="pb-4"
                >
                    <ReactPlayer 
                        url={props.block.video} 
                        controls={true} 
                        className="embed-responsive embed-responsive-16by9" 
                        width="100%" 
                        height="auto" 
                    />
                    {props.block.caption &&
                        <figcaption><p dangerouslySetInnerHTML={{ __html: props.block.caption }} /></figcaption>
                    }
                </ContainerMax>
            </div>
        )
    }
    return ""
}

export default VideoBlock

VideoBlock.defaultProps = {
    fluid: true
}