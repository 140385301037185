import React from "react"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "reactstrap"
import { media } from "utils/Media"
import ContainerMax from "components/shared/ContainerMax"
import WPLink from "components/shared/WPLink"
import SnowflakeBg from "components/shared/SnowflakeBg"
import snowflakeImg from "images/snowflake-covid.svg"

const PreText = styled.div`
    text-align: center;

    @media ${media.lg} {
        text-align: left;
    }

    ${props => props.itemCount === 4 && css`
        @media ${media.lg} {
            text-align: center;
        }
        @media ${media.xl} {
            text-align: left;
        }
    `}

    h1, h2, h3, h4, h5 {
        font-family: ${props => props.theme.font.family.heading};
        font-style: italic;
        font-weight: ${props => props.theme.font.weight.bold};

        ${props => props.pre && css`
            font-size: 1.875rem;

            @media ${media.sm} {
                font-size: 2.5rem;
            }
        
            @media ${media.xl} {
                font-size: 3.5rem;  
            }
        `}
    }
`

const Item = styled.div`
    padding: 1.5rem 15px;

    @media ${media.lg} {
        padding: 0 10px;
    }

    ${props => props.itemCount === 4 && css`
        @media ${media.lg} {
            padding: 1.5rem 15px;
        }
        @media ${media.xl} {
            padding: 0 10px;
        }
    `}

    .iconWrap {
        display: flex;
        align-items: center;
        padding-bottom: .5rem;
        justify-content: center;
    
        @media ${media.lg} {
            justify-content: flex-start;
        }

        span {
            color: ${props => props.theme.colors.primary};
            font-family: ${props => props.theme.font.family.condensed};
            font-weight: ${props => props.theme.font.weight.bold};
            font-size: 4rem;
            padding-right: 1rem;
        }
    }
`

const PreBook = (props) => {
	const { block } = props
    const itemCount = block.items.length
    const col = itemCount === 4 ? 3 : 4

	const getItems = block.items && block.items.map((item, i) => {
		return(
            <Col md={col} key={i}>
                <Item itemCount={itemCount}>
                    <div className="iconWrap">
                        <GatsbyImage
                            image={item.icon.localFile.childImageSharp.gatsbyImageData}
                            alt={item.icon.altText}
                        />
                    </div>
                    {item.text && 
                        <PreText dangerouslySetInnerHTML={{ __html: item.text }} />
                    }
                </Item>
            </Col>

		)
	})

    return(
        <Container fluid className="position-relative px-0">
            <ContainerMax className="py-4 py-xl-5" maxWidth="1300">
                <SnowflakeBg src={snowflakeImg} alt="" />
                <Row>
                    {block.text && 
                        <Col xs={12} lg={itemCount === 4 ? 12 : 3} xl={3}>
                            <PreText itemCount={itemCount} dangerouslySetInnerHTML={{ __html: block.text }} pre /> 
                            {/*do we have a view all link?*/}
                            {block.link &&
                                <div className="d-none d-lg-block pt-md-4">
                                    <WPLink url={block.link.url} target={block.link.target} button color="secondary">
                                        {block.link.title}
                                    </WPLink>
                                </div>
                            }
                        </Col>
                    }
                    <Col xs={12} lg={itemCount === 4 ? 12 : 9} xl={9}>
                        <Row>
                            {getItems}
                        </Row>
                    </Col>
                </Row>

                {block.image &&
                    <div className="text-center pt-4">
                        <GatsbyImage
                            image={block.image.localFile.childImageSharp.gatsbyImageData}
                            alt={block.image.altText}
                        />
                    </div>
                }

                {block.link &&
                    <div className="text-center d-lg-none">
                        <WPLink url={block.link.url} target={block.link.target} button color="secondary">
                            {block.link.title}
                        </WPLink>
                    </div>
                }
            </ContainerMax>
        </Container>
    )
}

export default PreBook