import React, { Component } from "react"
import styled from "styled-components"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { media } from "utils/Media"
import FilterBar from "components/shared/FilterBar"
import ListingFilterDropdown from "./ListingFilterDropdown"
import Button from "components/shared/Button"
import filter from "images/icon-filters.svg"

const FilterTrigger = styled.button`
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: 0;
    text-align: center;
    font-weight: ${props => props.theme.font.weight.bold};
    margin-bottom: 1.5rem;
    padding: .75rem;

    @media ${media.lg} {
        display: none;
    }
`

class ListingFilterBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter1: [],
            filter2: [],
            filter3: [],
            filter1Text: "",
            filter2Text: "",
            filter3Text: "",
            active: false
        }

        this.setFilter = this.setFilter.bind(this)
        this.resetFilters = this.resetFilters.bind(this)
        this.triggerFilterOverlay = this.triggerFilterOverlay.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (this.props.filter1 && this.props.filter1.name !== prevProps.filter1.name) {
            this.setState({
                filter1Text: `${this.props.filter1.name}`
            })
        }
        if (this.props.filter2 && this.props.filter2.name !== prevProps.filter2.name) {
            this.setState({
                filter2Text: `${this.props.filter2.name}`
            })
        }
        if (this.props.filter3 && this.props.filter3.name !== prevProps.filter3.name) {
            this.setState({
                filter3Text: `${this.props.filter3.name}`
            })
        }
    }

    triggerFilterOverlay() {
        this.setState({
            active: !this.state.active
        })
        document.body.classList.toggle('dropdown-filter-active')
    }
    
    /**
     * set the filter
     */
    setFilter = (databaseId, name, filter) => {
        let stateFilter
        if (filter === 1) {
            stateFilter = this.props.filter1.name 
        } else if (filter === 2) {
            stateFilter = this.props.filter2.name 
        } else {
            stateFilter = this.props.filter3.name 
        }
        window.analytics.track("Filter click", {
            filter: stateFilter,
            filterName: name
        })

        // Add or remove filter from array
        let selectedFilters
        if (filter === 1) {
            selectedFilters = this.props.selectedFilters1 
        } else if (filter === 2) {
            selectedFilters = this.props.selectedFilters2
        } else {
            selectedFilters = this.props.selectedFilters3
        }

        const newSelectedFilters = selectedFilters.includes(databaseId)
            ? selectedFilters.filter(i => i !== databaseId) // remove item
            : [ ...selectedFilters, databaseId ]; // add item

        // Update parent component filters so results update
        this.props.setFilters(newSelectedFilters, filter)

        // Update dropdown filter text
        if (filter === 1) {
            this.setState({
                filter1Text: newSelectedFilters.length > 0 ? `${this.props.filter1.name}` : `${this.props.filter1.name}`
            })
        } else if (filter === 2) {
            this.setState({
                filter2Text: newSelectedFilters.length > 0 ? `${this.props.filter2.name}` : `${this.props.filter2.name}`
            })
        } else {
            this.setState({
                filter3Text: newSelectedFilters.length > 0 ? `${this.props.filter3.name}` : `${this.props.filter3.name}`
            })
        }
    }

    resetFilters() {
        this.props.resetFilters()
        this.setState({
            filter1Text: `${this.props.filter1.name}`,
            filter2Text: `${this.props.filter2.name}`,
            filter3Text: `${this.props.filter3.name}`
        })
    }

    render() {
        const { filter1, filter2, filter3, filter1Label, filter2Label, filter3Label} = this.props
        if ((filter1 && filter1.length > 0) || (filter2 && filter2.length > 0) || (filter3 && filter3.length > 0)) {
            return(
                <>
                    <FilterTrigger onClick={this.triggerFilterOverlay}>
                        FILTERS <img src={filter} alt="" className="pl-3" />
                    </FilterTrigger>
                    <FilterBar className={classNames({
                        active: this.state.active
                    })}>
                        <button onClick={this.triggerFilterOverlay} className="filter-close">
                            <span className="sr-only">Close</span>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                        <div className="filter-title">FILTER BY</div>

                        {filter1 && filter1.length > 0 && 
                            <ListingFilterDropdown
                                filter={1}
                                filters={filter1}
                                selectedFilters={this.props.selectedFilters1}
                                setFilter={this.setFilter}
                                filterText={this.state.filter1Text}
                            />
                        }

                        {filter2 && filter2.length > 0 && 
                            <ListingFilterDropdown
                                filter={2}
                                filters={filter2}
                                selectedFilters={this.props.selectedFilters2}
                                setFilter={this.setFilter}
                                filterText={this.state.filter2Text}
                            />
                        }

                        {filter3 && filter3.length > 0 && 
                            <ListingFilterDropdown
                                filter={3}
                                filters={filter3}
                                selectedFilters={this.props.selectedFilters3}
                                setFilter={this.setFilter}
                                filterText={this.state.filter3Text}
                            />
                        }

                        <Button onClick={this.resetFilters} color="secondary" className="d-none d-lg-block">
                            Clear
                        </Button>

                        <div className="d-lg-none d-flex justify-content-between filter-buttons">
                            <Button onClick={this.resetFilters} color="grey">
                                Clear
                            </Button>
                            <Button color="secondary" onClick={this.triggerFilterOverlay}>
                                Apply
                            </Button>
                        </div>
                    </FilterBar>
                </>
            )
        } else return null
    }
}

export default ListingFilterBar

// import React, { Component } from "react"
// import styled from "styled-components"
// import classNames from "classnames"
// import queryString from "query-string"
// import { media } from "utils/Media"
// import ListingFilterDropdown from "./ListingFilterDropdown"
// import FilterBar from "components/shared/FilterBar"
// import Button from "components/shared/Button"
// import filter from "images/icon-filters.svg"

// const FilterTrigger = styled.button`
//     width: 100%;
//     background-color: white;
//     box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
//     border-radius: 3px;
//     border: 0;
//     text-align: center;
//     font-weight: ${props => props.theme.font.weight.bold};
//     margin-bottom: 1.5rem;
//     padding: .75rem;

//     @media ${media.lg} {
//         display: none;
//     }
// `

// class ListingFilterBar extends Component {
//     constructor(props) {
//         super(props)

//         this.state = {
//             active: false
//         }

//         this.triggerFilterOverlay = this.triggerFilterOverlay.bind(this)
//         this.setFilter = this.setFilter.bind(this)
//     }

//     triggerFilterOverlay() {
//         this.setState({
//             active: !this.state.active
//         })
//         document.body.classList.toggle('dropdown-filter-active')
//     }

//     /**
//      * set the filter
//      * value = databaseId or date
//      */
//     setFilter = (value, filter, inputType) => {
//         // name required as prop if tracking
//         // window.analytics.track("Filter click", {
//         //     filter: filter === 1 ? this.props.filter1.name : this.props.filter2.name,
//         //     filterName: name
//         // })

//         let newSelectedFilters, databaseId
//         const selectedFilters = this.props[filter]

//         if (filter === "selectedDateFilters") {
//             newSelectedFilters = value
//         } else if (inputType === "radio") {
//             databaseId = parseInt(value)
//             newSelectedFilters = selectedFilters.includes(databaseId) ? [] : [databaseId]
//         } else {
//             // Add or remove filter from array
//             databaseId = parseInt(value)
//             newSelectedFilters = selectedFilters.includes(databaseId)
//                 ? selectedFilters.filter(i => i !== databaseId) // remove item
//                 : [ ...selectedFilters, databaseId ]; // add item
//         }

//         /** 
//          * Apply filters for url, used so they can link with pre-defined filters
//          * */
//         // get query string
//         const urlString = typeof window !== 'undefined' ? window.location.search : ''

//         // parse query string into json
//         const parsed = queryString.parse(urlString)

//         // set filter with new filters
//         parsed[filter] = newSelectedFilters

//         // stringify filters to url query string
//         const stringified = queryString.stringify(parsed, { arrayFormat: 'comma' });

//         // set new url with correct filters
//         if (window.history.replaceState) {
//             var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + stringified
//             window.history.replaceState({ path: newurl }, '', newurl)
//         }

//         // Update parent component filters so results update
//         this.props.setFilters(newSelectedFilters, filter)
//     }

//     render() {
//         const {
//             dateFilters,
//             sessionFilters,
//             ageFilters,
//             categoryFilters,
//             selectedDateFilters,
//             selectedSessionFilters,
//             selectedAgeFilters,
//             selectedCategoryFilters,
//             resetFilters
//         } = this.props
//         return (
//             <>
//                 <FilterTrigger onClick={this.triggerFilterOverlay}>
//                     FILTERS <img src={filter} alt="" className="pl-3" />
//                 </FilterTrigger>
//                 <FilterBar className={classNames({
//                     active: this.state.active
//                 })}>


//                     <ListingFilterDropdown
//                         filter="selectedDateFilters"
//                         filters={dateFilters}
//                         selectedFilters={selectedDateFilters}
//                         setFilter={this.setFilter}
//                         filterText="SELECT DATE"
//                         type="date"
//                     />

//                     <ListingFilterDropdown
//                         filter="selectedSessionFilters"
//                         filters={sessionFilters}
//                         selectedFilters={selectedSessionFilters}
//                         setFilter={this.setFilter}
//                         filterText="SELECT YOUR ENTRY TIME"
//                         inputType="radio"
//                     />

//                     <ListingFilterDropdown
//                         filter="selectedAgeFilters"
//                         filters={ageFilters}
//                         selectedFilters={selectedAgeFilters}
//                         setFilter={this.setFilter}
//                         filterText="SELECT AGE RANGE"
//                     />

//                     <ListingFilterDropdown
//                         filter="selectedCategoryFilters"
//                         filters={categoryFilters}
//                         selectedFilters={selectedCategoryFilters}
//                         setFilter={this.setFilter}
//                         filterText="SELECT CATEGORIES"
//                         last
//                     />

//                     <div className="d-lg-none d-flex justify-content-between filter-buttons">
//                         <Button onClick={resetFilters} color="grey">
//                             Clear
//                         </Button>
//                         <Button color="secondary" onClick={this.triggerFilterOverlay}>
//                             Apply
//                         </Button>
//                     </div>
//                 </FilterBar>
//             </>
//         )
//     }
// }

// export default ListingFilterBar