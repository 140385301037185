import React, { useEffect } from "react"
// import LazyLoad from "react-lazyload"
import LandingHero from "./Blocks/LandingHero"
import Hero from "./Blocks/Hero"
import PageTitle from "./Blocks/PageTitle"
import PreBook from "./Blocks/PreBook"
import TextFullWidth from "./Blocks/TextFullWidth"
import TextWithAside from "./Blocks/TextWithAside"
// import Availability from "./Blocks/Availability"
import TwoImageBlock from "./Blocks/TwoImageBlock"
import FullWidthImage from "./Blocks/FullWidthImage"
import TheresMore from "./Blocks/TheresMore"
import ThingsToDoListing from "./Blocks/ThingsToDoListing/ThingsToDoListing"
import TextWithSidebar from "./Blocks/TextWithSidebar"
import FAQs from "./Blocks/FAQs"
import ThingToDoFullWidth from "./Blocks/ThingToDoFullWidth"
import ThingsToDo3Columns from "./Blocks/ThingsToDo3Columns"
import VideoBlock from "./Blocks/VideoBlock"
import Covid3Block from "./Blocks/Covid3Block"
import Partners from "./Blocks/Partners"
import Operators from "./Blocks/Operators"
import GettingHere from "./Blocks/GettingHere"
import ActivityPlannerBanner from "./Blocks/ActivityPlannerBanner"
import NewsListing from "./Blocks/NewsListing"
import MapFAQ from "./Blocks/MapFAQ"
import CovidRules from "./Blocks/CovidRules"
import EntranceGates from "./Blocks/EntranceGates"
import TransportDropdowns from "./Blocks/TransportDropdowns"
import TwoColumnTextWithBorders from "./Blocks/TwoColumnTextWithBorders"
import Breadcrumbs from "./Blocks/Breadcrumbs"
import Image from "./Blocks/Image"
// import GalleryCarousel from "./TwoColumnBlocks/GalleryCarousel"
import ImageCarousel from "./Blocks/ImageCarousel"
import App from "./Blocks/App"
import Listing from "./Blocks/Listing/Listing"
import Galleries from "./Blocks/Galleries"
import NewsletterSignup from "./Blocks/NewsletterSignup"
import ThreeColumnsBlock from "./Blocks/ThreeColumnsBlock"
import Timeline from "./Blocks/Timeline"
import SocialWall from "./Blocks/SocialWall"
import ActivityPlanner from "./Blocks/ActivityPlanner/ActivityPlanner"

const FlexibleContent = ({blocks, pageTitle, seo}) => {
	useEffect(() => {
		const addPaddingToPage = () => {
			setTimeout(() => {
				const header = document.getElementsByClassName("headerWrap")[0];
				if (header !== undefined) {
					const headerHeight = header.clientHeight
					document.getElementsByClassName("siteWrap")[0].style.paddingTop = `${headerHeight + 20}px` // 30 = extra padding
				}
			}, 200);
		}

		if (blocks !== null) {
			let count = 0
			blocks.forEach((acf) => {
				// If first block is not a hero add padding so content sits beneath fixed header
				if (count === 0) {
					if (
						acf.__typename !== "WpDefaultTemplate_Acfflexiblecontent_Blocks_LandingHero" &&
						acf.__typename !== "WpDefaultTemplate_Acfflexiblecontent_Blocks_Hero"
					) {
						addPaddingToPage()
						window.addEventListener("resize", addPaddingToPage)
					}
				}
				count++
			})
		}

		return () => {
      window.removeEventListener('resize', addPaddingToPage)
    }
	}, [])

	let blocksHtml = null

	if (blocks !== null) {
		blocksHtml = blocks.map((acf, i) => {
			// console.log(acf.__typename)

			switch (acf.__typename) {
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_LandingHero':
					return <LandingHero key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Hero':
					return <Hero key={i} block={acf} pageTitle={pageTitle} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_PageTitle':
					return <PageTitle key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_PreBook':
					return <PreBook key={i} block={acf} />
				// case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Availability':
				//   return <Availability key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_TwoImageBlock':
					return <TwoImageBlock key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_FullWidthImage':
					return <FullWidthImage key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_TheresMore':
					return <TheresMore key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_ThingsToDoListing':
					return <ThingsToDoListing key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_TextFullWidth':
					return <TextFullWidth key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_TextWithAside':
					return <TextWithAside key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_TextBlockWithSidebar':
					return <TextWithSidebar key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Faqs':
					return <FAQs key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_ThingToDoFullWidth':
					return <ThingToDoFullWidth key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_ThingsToDo3Columns':
					return <ThingsToDo3Columns key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_VideoBlock':
					return <VideoBlock key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Covid3Block':
					return <Covid3Block key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Partners':
					return <Partners key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Operators':
					return <Operators key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_GettingHere':
					return <GettingHere key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_NewsListing':
					return <NewsListing key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_MapFaq':
					return <MapFAQ key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_EntranceGates':
					return <EntranceGates key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_TransportDropdowns':
					return <TransportDropdowns key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_TwoColumnTextWithBorders':
					return <TwoColumnTextWithBorders key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Breadcrumbs':
					return <Breadcrumbs key={i} block={seo} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_CovidRules':
					return <CovidRules key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Image':
					return <Image key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_ActivityPlannerBanner':
					return <ActivityPlannerBanner key={i} block={acf} />
				// case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Carousel':
				//     return <GalleryCarousel data={acf} key={i} template="default" />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_ImageCarousel':
					return <ImageCarousel block={acf} key={i} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_App':
					return <App block={acf} key={i} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Listing':
					return <Listing block={acf} key={i} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Galleries':
					return <Galleries key={i} block={acf} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_NewsletterSignup':
					return <NewsletterSignup block={acf} key={i} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_ThreeColumnsBlock':
					return <ThreeColumnsBlock block={acf} key={i} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_Timeline':
					return <Timeline block={acf} key={i} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_SocialWall':
					return <SocialWall block={acf} key={i} />
				case 'WpDefaultTemplate_Acfflexiblecontent_Blocks_ActivityPlanner':
					return <ActivityPlanner block={acf} key={i} />
					
				default:
					return null;
			}
		})
	}

	return blocksHtml
}

export default FlexibleContent