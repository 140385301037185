import React from "react"
import { Container } from "reactstrap"
import styled, { css } from "styled-components"
import { media } from "utils/Media"

const Bar = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 38px;

  @media ${media.md} {
    height: 100px;
  }

  &:after {
    content: "";
    position: absolute;
    height: 4px;
    width: 75%;
    left: 50%;
    transform: translateX(-50%);
    top: 15px;
    background: ${props => props.theme.colors.secondary};
    opacity: .2;
  }
`

const ProgressStep = styled.button`
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 10;
  width: 25%;
  pointer-events: none;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #BABFF4;
    font-weight: ${props => props.theme.font.weight.bold};
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid ${props => props.theme.colors.secondary};
    
    
    ${props => props.active && css`
      background-color: ${props => props.theme.colors.pink};
      border-color: ${props => props.theme.colors.pink};
      color: white;
    `}

    ${props => props.complete && css`
      background-color: #FFB0CD;
      border-color: #FFB0CD;
      color: white;
      pointer-events: auto;
    `}
  }

  p {
    width: 100%;
    font-family: ${props => props.theme.font.family.condensed};
    color: #BABFF4;
    text-transform: uppercase;
    display: none;

    @media ${media.md} {
      display: block;
    }
    
    ${props => props.active && css`
      font-weight: ${props => props.theme.font.weight.bold};
      color: ${props => props.theme.colors.secondary};
    `}

    ${props => props.complete && css`
      color: #BABFF4;
    `}
  }
`

export default function ActivityPlannerProgress({steps, activeStep, setActiveStep}) {

  const changeStep = (step) => {
    if (steps[step-1].activeFilters?.length > 0) {
      setActiveStep(step)
    }
  }

  return(
    <Container>
      <Bar>
        {steps?.map((step, i) => {
          return(
            <ProgressStep 
              key={`step${i}`}
              onClick={() => changeStep(i+1)}
              active={(i + 1) === activeStep} 
              complete={step.activeFilters?.length > 0 && (i + 1) !== activeStep}
            >
              <span>{i + 1}</span>
              <p>{step.shortTitle}</p>
            </ProgressStep>
          )
        })}
      </Bar>
    </Container>
  )
}