import React, { Component } from "react"
import { Container, Row } from "reactstrap"
import styled from "styled-components"
import ContainerMax from "components/shared/ContainerMax"
import Text from "components/shared/Text"
import ListingItem from "components/FlexibleContent/Blocks/ThingsToDoListing/ListingItem"
import SnowflakeBg from "components/shared/SnowflakeBg"
import snowflakeImg from "images/snowflake-food-drink.svg"

const SnowflakeBgStyled = styled(SnowflakeBg)`
    top: 2rem;
`

class ThingsToDo3Columns extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ajsId: ""
        }
    }

    componentDidMount() {
        this.setAjsId()
    }
    
    setAjsId() {
        if (window.analytics && typeof window.analytics.user !== "undefined") {
            this.setState({
                ajsId: window.analytics.user().anonymousId()
            })
        }
    }

    render() {
        const { block } = this.props

        const items = block.thingsToDo && block.thingsToDo.map((node, i) => {
            return <ListingItem key={i} data={node} forceFeatured={false} threeColumnBlock={true} ajsId={this.state.ajsId} />
        })
    
        return(
            <Container fluid className="position-relative px-0">
                {block.title &&
                    <ContainerMax className="text-center">
                        <Text as="h2" title className="mb-3">{block.title}</Text>
                    </ContainerMax>
                }
                <ContainerMax className="pb-md-4">
                    <SnowflakeBgStyled src={snowflakeImg} alt="" />
                    <Row>
                        {items}
                    </Row>
                </ContainerMax>
            </Container>
        )
    }
}

export default ThingsToDo3Columns