import styled from "styled-components"
import { media } from "utils/Media"

const DropdownStyled = styled.div`
    position: relative;
    z-index: 10;
    min-width: 140px;
    font-size: ${props => props.theme.font.size.sm};
    margin-bottom: 1rem;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));

    @media ${media.lg} {
        margin-bottom: 0;
        min-width: 215px;
        border: 0;
    }

    @media ${media.lg} {
        margin-bottom: 0;
        min-width: 235px;
        border: 0;
    }
`

export default DropdownStyled