import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import ListingItem from "../ThingsToDoListing/ListingItem"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft, faShare, faTimes, faLink, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faFacebookSquare, faWhatsappSquare, faSquareXTwitter } from "@fortawesome/free-brands-svg-icons"
import Button from "components/shared/Button"
import WPLink from "components/shared/WPLink"
import { media } from "utils/Media"
import Text from "components/shared/Text"

const Error = styled.p`
  font-size: ${props => props.theme.font.size.lg};
  font-weight: ${props => props.theme.font.weight.bold};
`

const Title = styled(Text)`
  font-size: ${props => props.theme.font.h1.size};
`

const ShareModalStyled = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, .5);
  display: none;
  z-index: 1000;
  top: 0;
  left: 0;
  overflow-y: auto;

  ${props => props.active && css`
    display: block;
  `}

  button, .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    border-radius: 4px;
    border: 2px solid ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.secondary};
    cursor: pointer;
    padding: .5rem 2rem;
    text-transform: uppercase;
    font-family: ${props => props.theme.font.family.condensed};
    font-size: ${props => props.theme.font.size.lg};
    font-weight: ${props => props.theme.font.weight.semiBold};

    @media ${media.md} {
      padding: .5rem 4rem;
      font-size: ${props => props.theme.font.h4.size};
    }
  }
  
  button:hover, .button:hover {
    background-color: ${props => props.theme.colors.secondary};
    color: white;
  }
  
  .share-button, .share-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .share-dialog {
    width: 90%;
    max-width: 800px;
    box-shadow: 0 8px 16px rgba(0,0,0,.15);
    border: 1px solid #ddd;
    padding: 2rem;
    border-radius: 10px;
    background-color: #fff;
  }

  header {
    display: block;
    text-align: center;
    margin-bottom: 20px;

    h3 {
      font-size: ${props => props.theme.font.h3.size};
    }

    button {
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      border: none;
      padding: 1rem;
      color: ${props => props.theme.colors.secondary};
    }
  }

  .targets {
    display: flex;
    flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px;

    @media ${media.md} {
      padding: 0 4rem;
    }

    div {
      width: 100%;
      padding-bottom: 1rem;

      @media ${media.md} {
        width: 50%;
        padding: 0 1rem 1rem 1rem;
      }

      .button {
        width: 100%;
      }
    }
  }

  .link {
    padding: 1rem;
    border-radius: 4px;
    background-color: #BBECFA;

    @media ${media.md} {
      padding: .5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .pen-url {
      margin-right: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: ${props => props.theme.colors.secondary};
      font-weight: ${props => props.theme.font.weight.bold};
      font-style: italic;
    }

    .share-button, .copy-link {
      border: 2px solid ${props => props.theme.colors.secondary};
      background: transparent;
      padding: .5rem 4rem;
      width: 100%;
      margin-top: .5rem;

      @media ${media.md} {
        width: auto;
        margin-top: 0;
      }

      &:hover {
        background-color: ${props => props.theme.colors.secondary};
        color: white;
      }
    }
  }
`

export default function ActivityPlannerResults({ activeFilters, reset, sharePage }) {
  const [modalActive, setModalActive] = useState(false)
  const title = "Hyde Park Winter Wonderland - Activiy Planner"
  const text = "Check out my itinerary for Hyde Park Winter Wonderland!"
  const url = `${process.env.GATSBY_FRONTEND_URL}${sharePage.uri}${window.location.search}`.replace(/([^:])\/\//, '$1/')

  const data = useStaticQuery(graphql`
    query {
      allWpThingtodo(
        limit: 100
        filter: {status: {eq: "publish"}}
        sort: { fields: [menuOrder], order: ASC }
      ) {
        edges {
          node {
            ...thingtodoFragment
          }
        }
      }
    }
  `)

  const share = () => {
    if (navigator.share) { 
      navigator.share({
        title: title,
        text: text,
        url: url
      }).then(() => {
        console.log('Thanks for sharing!');
      })
      .catch(console.error);
    } else {
      setModalActive(true)
    }
  }

  let listingData = data.allWpThingtodo.edges

  // filter items
  activeFilters.forEach((filter, i) => {
    console.log(`ttdActivityPlanners${i + 1}`)
    listingData = listingData && listingData.filter(({node}) => {
      const nodeCategories = node[`ttdActivityPlanners${i + 1}`] && node[`ttdActivityPlanners${i + 1}`].nodes

      return nodeCategories?.find(node => filter.includes(node.databaseId))
    })
  });
  
  const items = listingData?.map(({node}, i) => {
    return(
      <Col xs={12} className="d-flex justify-content-center" key={`results${i}`}>
        <ListingItem  data={node} featured={false} activityPlanner={true} />
      </Col>
    )
  })

  return(
    <>
      <Container>
        <Title as="h2" title className="text-center pb-5">Your suggested itinerary</Title>

        {items.length > 0 ? (
          <Row>
            {items}
          </Row>
        ) : ( 
          <Error className="text-center">Sorry no results were found, please try again.</Error>
        )}

        <Row className="justify-content-center">
          <Col xs={12} md={10} xl={8}>
            <Row className="justify-content-center py-4">
              <Col sm={4} className="mb-2 sm-mb-0">
                <Button onClick={reset} className="w-100">
                  <FontAwesomeIcon icon={faAngleLeft} className="mr-2" /> Start again
                </Button>
              </Col>
          
              <Col sm={4} className="mb-2 sm-mb-0">
                <WPLink url="https://winterwonderland.seetickets.com/winterwonderland" target="_blank" button color="secondary" className="w-100">
                  Book Now
                </WPLink>
              </Col>

              <Col sm={4}>
                <Button onClick={share} className="w-100">
                  Share <FontAwesomeIcon icon={faShare} className="ml-2" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <ShareModal 
        active={modalActive} 
        setModalActive={setModalActive} 
        text={text}
        url={url}
      />
    </>
  )
}

const ShareModal = ({active, setModalActive, text, url}) => {
  const [whatsAppUrl, setWhatsAppUrl] = useState(null)
  const [facebookUrl, setFacebookUrl] = useState(null)
  const [xUrl, setXUrl] = useState(null)
  const [emailUrl, setEmailUrl] = useState(null)

  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url)
  }

  const shareLink = (type) => {
    const webAppURL = encodeURIComponent(url);
    let link

    switch (type) {
      case "whatsapp":
        link = `https://wa.me/?text=${webAppURL}`
        break;
      case "facebook":
        link = `https://www.facebook.com/sharer/sharer.php?u=${webAppURL}`;
        break;
      case "x":
        link = `https://twitter.com/intent/tweet?text=${text}&url=${webAppURL}`;
        break;
      case "email":
        link = `mailto:?subject=${text}&body=${webAppURL}`;
        break;
    
      default:
        break;
    }

    return link
  }

  useEffect(() => {
    setWhatsAppUrl(shareLink("whatsapp"))
    setFacebookUrl(shareLink("facebook"))
    setXUrl(shareLink("x"))
    setEmailUrl(shareLink("email"))
  }, [url]) 

  return(
    <ShareModalStyled active={active}>
      <div className="share-dialog">
        <header>
          <Text as="h3" className="dialog-title" title>Share your suggested itinerary</Text>
          <button onClick={() => setModalActive(false)}>
            <span className="sr-only">Close</span>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </header>
        <div className="targets">
          <div>
            <a href={whatsAppUrl} className="button" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsappSquare} className="mr-2" />
              <span>WhatsApp</span>
            </a>
          </div>
          
          <div>
            <a href={facebookUrl} className="button" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookSquare} className="mr-2" />
              <span>Facebook</span>
            </a>
          </div>
          
          <div>
            <a href={xUrl} className="button" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSquareXTwitter} className="mr-2" />
              <span>X</span>
            </a>
          </div>
          
          <div>
            <a href={emailUrl} className="button" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              <span>Email</span>
            </a>
          </div>
        </div>
        <div className="link">
          <div className="pen-url">{url}</div>
          <button className="copy-link" onClick={() => copyToClipboard(url)}>
            <FontAwesomeIcon icon={faLink} className="mr-3"/>
            Copy Link
          </button>
        </div>
      </div>
    </ShareModalStyled>
  )
}