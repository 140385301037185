import React from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import next from "images/btn-caret-next.svg"
import prev from "images/btn-caret-prev.svg"

const SliderStyled = styled(Slider)`
    padding: 0 0rem;
    margin: 0 auto;

    .slick-item {
        border: 0;
        padding: 0;
        background-color: transparent;
        opacity: .6;
    }
    .slick-active .slick-item {
      opacity: 1;
    }
    .slider-arrow {
        left: 0.5rem;
        background-image: url("${prev}");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 43px 43px;
        width: 45px;
        height: 45px;
        background-color: transparent;
        border: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 50;

        &.next {
          left: auto;
          right: 0.5rem;
          background-image: url("${next}");
        }
    }
`

const Slide = styled.div`
    border: 0;
    padding: 0;
    background-color: transparent;
`

const PopupGalleryCarousel = (props) => {
    const { images } = props

    function PrevButton({ onClick }) {
        return(
          <button onClick={onClick} className="slider-arrow prev" aria-label="Previous" />
        )
      }
      
      function NextButton({ onClick }) {
        return (
          <button onClick={onClick} className="slider-arrow next" aria-label="Next" />
        )
      }
    
    //slider settings
    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        lazyload: true,
        prevArrow: <PrevButton/>,
        nextArrow: <NextButton/>,
    }

    const sliderImages = images.map((image, i) => {
        if (image.image) {
            return(
                <Slide key={i}>
                    <GatsbyImage image={image.image.localFile.childImageSharp.gatsbyImageData} alt={image.altText} />
                </Slide>
            )
        } else return null
    })

    return(
        <SliderStyled {...settings}>
            {sliderImages}
        </SliderStyled>
    )
}

export default PopupGalleryCarousel