import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "reactstrap"
import { media } from "utils/Media"
import ContainerMax from "components/shared/ContainerMax"
import Text from "components/shared/Text"

const ContainerStyled = styled(Container)`
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: ${props => props.theme.colors.midBlue};
`;

const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1300px;
  margin: 0 auto;
  justify-content: center;

  @media ${media.lg} {
    flex-wrap: nowrap;
  }
`

const PreText = styled.div`
  text-align: center;
  color: white;
  font-family: ${props => props.theme.font.family.heading};
  font-style: italic;
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size.xl};
  padding-bottom: 2rem;
`

const Item = styled.div`
  padding: 1.5rem 15px;
  min-width: 150px;
  width: 100%;
  text-align: center;

  @media ${media.sm} {
    width: 50%;
  }

  @media ${media.md} {
    width: 33%;
  }

  @media ${media.lg} {
    padding: 0 10px;
    width: auto;
    text-align: left;
  }

  .iconWrap {
    display: flex;
    align-items: center;
    padding-bottom: .5rem;
    justify-content: center;
    padding-bottom: 1rem;

    @media ${media.lg} {
      justify-content: flex-start;
    }

    span {
      color: ${props => props.theme.colors.primary};
      font-family: ${props => props.theme.font.family.condensed};
      font-weight: ${props => props.theme.font.weight.bold};
      font-size: 4rem;
      padding-right: 1rem;
    }
  }
`

const CovidRules = (props) => {
	const { block } = props

	const getItems = block.blocks && block.blocks.map((item, i) => {
		return(
      <Item key={i}>
        <div className="iconWrap">
          <GatsbyImage
            image={item.icon.localFile.childImageSharp.gatsbyImageData}
            alt={item.icon.altText}
          />
        </div>
        {item.title && 
          <Text as="div" white heading italic dangerouslySetInnerHTML={{ __html: item.title }} />
        }
        {item.text && 
          <Text as="div" white dangerouslySetInnerHTML={{ __html: item.text }} />
        }
      </Item>
		)
	})

  return(
    <ContainerStyled fluid>
      <Container fluid className="position-relative px-0">
        <ContainerMax className="py-4 py-xl-5" maxWidth="1300">
          <Row>
            {block.preText && 
              <Col xs={12}>
                <PreText dangerouslySetInnerHTML={{ __html: block.preText }} pre /> 
              </Col>
            }
            <Col xs={12}>
              <FlexWrap>
                {getItems}
              </FlexWrap>
            </Col>
          </Row>
        </ContainerMax>
      </Container>
    </ContainerStyled>
  )
}

export default CovidRules