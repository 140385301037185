import React, { Component } from "react"
import { Link } from "gatsby"
import { Container } from "reactstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import WPLink from "components/shared/WPLink"
import ListingItemStyled from "components/FlexibleContent/Blocks/ThingsToDoListing/ListingItemStyled"
import ListingItemContent from "components/FlexibleContent/Blocks/ThingsToDoListing/ListingItemContent"

class ThingToDoFullWidth extends Component {
    constructor(props) {
        super(props)

        this.state = {
            descriptionActive: false,
            ajsId: ""
        }

        this.toggleDesc = this.toggleDesc.bind(this)
    }

    componentDidMount() {
        this.setAjsId()
    }

    setAjsId() {
        if (window.analytics && typeof window.analytics.user !== "undefined") {
            this.setState({
                ajsId: window.analytics.user().anonymousId()
            })
        }
    }

    toggleDesc() {
        this.setState({
            descriptionActive: !this.state.descriptionActive
        })
    }

    render() {
        const { block } = this.props

        const { 
            thingToDo,
            mobileImage,
            tabletImage,
            desktopImage
        } = block

        if (thingToDo) {
            const { 
                type,
                listingDescription
            } = thingToDo?.acfThingsToDo
        
            return(
                <Container fluid className="px-sm-0 position-relative mb-sm-4">
                    {tabletImage &&
                        <GatsbyImage
                            image={tabletImage.localFile.childImageSharp.gatsbyImageData} 
                            alt={tabletImage.altText} 
                            className="d-none d-sm-block d-lg-none"
                        />
                    }
                    {desktopImage &&
                        <GatsbyImage
                            image={desktopImage.localFile.childImageSharp.gatsbyImageData} 
                            alt={desktopImage.altText} 
                            className="d-none d-lg-block"
                        />
                    }
                    <Container className="px-0">
                        <ListingItemStyled featured={true} fullWidth={true}>
                            <div className="inner">
                                {type === "page" ? (
                                    <Link to={thingToDo.uri} className="image-link">
                                        <GatsbyImage
                                            image={mobileImage.localFile.childImageSharp.gatsbyImageData}
                                            alt={mobileImage.altText}
                                            className="d-sm-none"
                                        />
                                    </Link>
                                ) : (
                                    <GatsbyImage
                                        image={mobileImage.localFile.childImageSharp.gatsbyImageData}
                                        alt={mobileImage.altText}
                                        className="d-sm-none"
                                    />
                                )}
                                <div className="content">
                                    <ListingItemContent
                                        data={thingToDo}
                                        featured={true}
                                        descriptionActive={this.state.descriptionActive}
                                        toggleDesc={this.toggleDesc}
                                        ajsId={this.state.ajsId}
                                    />
                                </div>
                            </div>
                            {this.state.descriptionActive && 
                                <div className="d-md-none">
                                    {listingDescription &&
                                        <div 
                                            dangerouslySetInnerHTML={{ __html: listingDescription }} 
                                            className="py-3"
                                        />
                                    }
                                    {type === "page" &&
                                        <WPLink url={thingToDo.uri} button color="orange" className="mr-3">
                                            MORE
                                        </WPLink>
                                    }
                                </div>
                            }
                        </ListingItemStyled>
                    </Container>
                </Container>
            )     
        } else return null
    }
}

export default ThingToDoFullWidth