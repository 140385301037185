import React from "react"
import { Container, Row, Col } from "reactstrap"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { media } from "utils/Media"
import ContainerMax from "components/shared/ContainerMax"
import Text from "components/shared/Text"
import WPLink from "components/shared/WPLink"
import download from "images/icon-download.svg"

const ContainerStyled = styled(Container)`
    background-color: ${props => props.theme.colors.midBlue};

    h3 {
        font-size: ${props => props.theme.font.h2.size};
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h1.size};
        }
    }
`

const Circle = styled.div`
    height: 30px;
    width: 30px;
    border-radius: 50%;
    padding-right: .5rem;

    ${props => props.color === "red" && css`
        background-color: ${props => props.theme.colors.scarlet};
    `}
    ${props => props.color === "blue" && css`
        background-color: ${props => props.theme.colors.cyan};
    `}
    ${props => props.color === "green" && css`
        background-color: ${props => props.theme.colors.neonGreen};
    `}
    ${props => props.color === "gold" && css`
        background-color: ${props => props.theme.colors.primary};
    `}
`

const Border = styled.div`
    border-bottom: 1px solid white;

    ${props => props.last && css`
        border: none;
    `}
`

const EntranceGates = (props) => {
    const { block } = props
    const { image, title, preText, gates, bottomText, downloadFile, downloadButtonText } = block

    return(
        <ContainerStyled fluid className="py-5">
            <Row>
                <ContainerMax maxWidth="1600">
                    <Row>
                        <Col lg={6} className="text-center text-md-left">
                            {block.image &&
                                <GatsbyImage
                                    image={image.localFile.childImageSharp.gatsbyImageData}
                                    alt={image.altText}
                                    className="mb-4 mb-lg-0"
                                />
                            }
                        </Col>
                        <Col lg={6}>
                            <div className="pl-md-4">
                                {title &&
                                    <Text as="h3" uppercase white heading italic className="pb-4">
                                        {title}
                                    </Text>
                                }
                                <Row>
                                    {preText &&
                                        <Col>
                                            <Text
                                                as="div"
                                                white
                                                className="pb-4"
                                                dangerouslySetInnerHTML={{ __html: preText }}
                                            />
                                        </Col>
                                    }
                                    {gates && gates.map((gate, i) => {
                                        return(
                                            <Col key={i} xs={12} className="pb-3 pt-1">
                                                <Border last={i === gates.length}>
                                                    <Row>
                                                        <Col xs="auto">
                                                            <Circle color={gate.colour} />
                                                        </Col>
                                                        <Col xs="auto">
                                                            <Text as="h4" uppercase bold white condensed>{gate.title}</Text>
                                                        </Col>
                                                    </Row>
                                                    {gate.text &&
                                                        <Text as="div" className="pb-3" white dangerouslySetInnerHTML={{ __html: gate.text }} />
                                                    }
                                                </Border>
                                            </Col>
                                        )
                                    })}
                                    {bottomText &&
                                        <Col>
                                            <Text
                                                as="div"
                                                white
                                                className="pt-3 pb-4"
                                                dangerouslySetInnerHTML={{ __html: bottomText }}
                                            />
                                        </Col>
                                    }
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    {downloadFile &&
                        <Row className="justify-content-center pt-3 pt-md-5">
                            <Col xs="auto">
                                <WPLink
                                    url={downloadFile.localFile.publicURL} 
                                    target="_blank"
                                    aria-label="Opens new window"
                                    button
                                    color="white"
                                    rel="noopener noreferrer">
                                        {downloadButtonText ? downloadButtonText : "DOWNLOAD"} <img src={download} alt="Download" />
                                </WPLink>
                            </Col>
                        </Row>
                    }
                </ContainerMax>
            </Row>
        </ContainerStyled>
    )
}

export default EntranceGates