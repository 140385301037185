import React, { Component } from "react"
import { TabContent, TabPane, Row, Col, UncontrolledCollapse } from "reactstrap"
import styled from "styled-components"
import classNames from "classnames"
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { media } from "utils/Media"
import ContainerMax from "components/shared/ContainerMax"
import { LinkSearchReplace } from "utils/LinkSearchReplace"
import snowflake from "images/snowflake-twocol.svg"

const Wrap = styled.div`
    overflow: hidden;
`

const SectionButton = styled.button`
    padding: 0;
    margin: 0 0 .5rem 0;
    background-color: transparent;
    border: 0;
    display: flex;
    /* align-items: center; */
    font-family: ${props => props.theme.font.family.condensed};
    font-weight: ${props => props.theme.font.weight.bold};
    font-size: ${props => props.theme.font.size.xl};
    color: ${props => props.theme.colors.black};
    text-transform: uppercase;
    text-align: left;
    line-height: 1.1;

    &:focus {
        outline: 0;
    }
    
    .icon {
        flex: 0 0 35px;
    }

    &.active {
        color: ${props => props.theme.colors.secondary};
        font-family: ${props => props.theme.font.family.condensed};
        font-weight: ${props => props.theme.font.weight.bold};
    }
`

const FAQButton = styled.button`
    display: block;
    width: 100%;
    background-color: transparent;
    padding: 1rem 1rem 1rem 0;
    border: 0;
    border-top: 1px solid #c4c4c4;
    text-align: left;
    font-size: ${props => props.theme.font.size.xl};
    font-family: ${props => props.theme.font.family.condensed};
    font-weight: ${props => props.theme.font.weight.bold};
    text-transform: uppercase;
    
    &:focus {
        outline: 0;
    }

    .icon {
        position: absolute;
        right: 1rem;
        color: ${props => props.theme.colors.secondary};
        transform: rotate(0);

        svg {
            max-width: 12px;
        }
    }

    &.active {
        color: ${props => props.theme.colors.black};
        .icon {
            transform: rotate(180deg);
        }
    }
`

const SnowflakeStyled = styled.img`
    position: absolute;
    left: 0;
    top: 5rem;
    display: none;

    @media ${media.xl} {
        display: block;
    }
`

class FAQs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeSection: 0
        }
    }

    toggleSection(tab) {
        if (this.state.activeSection !== tab) {
            this.setState({
                activeSection: tab
            })
        }
    }

    toggleClass(id) {
        document.getElementById(id).classList.toggle("active")
    }

    render() {
        return (
            <Wrap className="position-relative">
                <SnowflakeStyled src={snowflake} alt="" />
                <ContainerMax maxWidth="1300" className="py-5">
                    <Row>
                        <Col md={3}>
                            {this.props.block.section && this.props.block.section.map((sec, i) => {
                                return (
                                    <SectionButton 
                                        key={i}
                                        onClick={() => { this.toggleSection(i) }} 
                                        className={classNames({ active: this.state.activeSection === i })}
                                    >
                                        {sec.icon &&
                                            <div className="icon">
                                                <GatsbyImage
                                                    image={sec.icon.localFile.childImageSharp.gatsbyImageData}
                                                    alt={sec.icon.altText}
                                                    className="mr-2"
                                                />
                                            </div>
                                        }
                                        {sec.title}
                                    </SectionButton>
                                )
                            })}
                        </Col>
                        <Col md={8}>
                            <TabContent activeTab={this.state.activeSection}>
                                {this.props.block.section && this.props.block.section.map((sec, secId) => {
                                    return (
                                        <TabPane tabId={secId} key={secId}>
                                            {sec.faqs.map((faq, faqId) => {
                                                return(
                                                    <Row key={faqId}>
                                                        <Col sm="12">
                                                            <FAQButton id={`faq${secId}${faqId}`} onClick={() => this.toggleClass(`faq${secId}${faqId}`)} onTouchStart={() => this.toggleClass(`faq${secId}${faqId}`)}>
                                                                {faq.question}
                                                                <FontAwesomeIcon icon={faAngleDown} className="icon" />
                                                            </FAQButton>
                                                            <UncontrolledCollapse toggler={`#faq${secId}${faqId}`}>
                                                                <div dangerouslySetInnerHTML={{ __html: LinkSearchReplace(faq.answer) }} className="pb-3"/>
                                                            </UncontrolledCollapse>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}
                                        </TabPane>
                                    )
                                })}
                            </TabContent>
                        </Col>
                    </Row>
                </ContainerMax>
            </Wrap>
        )
    }
}

export default FAQs