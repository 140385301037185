import React from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import ContainerMax from "components/shared/ContainerMax"
import Text from "components/shared/Text"

const TextStyled = styled(Text)`
    padding-left: 5px;
`

const PageTitle = (props) => {
    const { align, title } = props.block

    return(
        <ContainerMax maxWidth="1300" className="py-4">
            <Row>
                <Col>
                    <TextStyled as="h2" title className={`${(props.left || align === "left") ? "" : "text-center"} m-0`}>{title}</TextStyled>
                </Col>
            </Row> 
        </ContainerMax>
    )
}

export default PageTitle