import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container } from "reactstrap"
import styled from "styled-components"
import { media } from "utils/Media"
import ContentMax from "components/shared/ContentMax"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import Text from "components/shared/Text"
import { LinkSearchReplace } from "utils/LinkSearchReplace"
import WPLink from "components/shared/WPLink"
import Button from "components/shared/Button"
import curve from "images/white-curve-1.svg"
import Curve from "components/shared/Curve"

const ContainerWrap = styled(Container)`
    position: relative;
    padding: 10rem 30px 2rem 30px;
    @media ${media.md} {
        padding: 15rem 30px 10rem 30px;
    }
    .bg-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

const ContentMaxStyled = styled(ContentMax)`
    /* position: absolute; */
    /* left: 50%; */
    /* transform: translateX(-50%); */
    /* bottom: 1rem; */
    z-index: 1;
    width: 100%;
    overflow-y: hidden;
    @media ${media.md} {
        bottom: auto;
        /* top: 50%; */
        /* transform: translate(-50%, -50%); */
    }
    
    .content {
        overflow-y: hidden;
        display: block;
        z-index: 3;
        width: calc(100% - 60px);
        background-color: white;
        padding: 1rem;
        padding-right: 4rem;
        border-radius: .5rem;
        text-decoration: none;
        margin-left: 30px;
        font-weight: normal;
        position: relative;
        @media ${media.md} {
            width: 100%;
            max-width: 640px;
            padding: 2rem;
        }
        svg {
            font-size: 2.5rem;
            color: ${props => props.theme.colors.secondary};
            padding-left: 1rem;
            flex-grow: 1;
            width: 50px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1rem;
        }
        h3 {
            display: block;
            padding-left: 5px;
            padding-right: 50px;
            @media ${media.md} {
                padding-right: 0;
            }
        }
    }
`

const TextStyled = styled.div`
    padding-bottom: 1rem;
    color: black;
    font-family: ${props => props.theme.font.family.base};
`


const FullWidthImage = (props) => {
    const { block } = props

    const ContentBlock = (props) => {
        const { block } = props
        return(
            <>
                {block.title &&
                    <Text as="h3" title smaller className="pb-md-3">
                        <span>{block.title}</span> 
                        <span className="text-right d-md-none">
                            <FontAwesomeIcon icon={faChevronRight} />
                        </span>
                    </Text>
                }
                {block.text &&
                    <TextStyled dangerouslySetInnerHTML={{ __html: LinkSearchReplace(block.text) }} />
                }
                {block.link &&
                    <Button as="div" color="secondary" className="d-none d-md-inline-block">
                        {block.link.title}
                    </Button>
                }
            </>
        )
    }

    return(
        <ContainerWrap fluid className="px-0 position-relative overflow-hidden">
            <GatsbyImage
                image={block.mobileImage.localFile.childImageSharp.gatsbyImageData} 
                alt={block.mobileImage.altText} 
                className="d-md-none bg-image"
            />
            <GatsbyImage
                image={block.tabletImage.localFile.childImageSharp.gatsbyImageData} 
                alt={block.tabletImage.altText} 
                className="d-none d-md-block d-lg-none bg-image"
            />
            <GatsbyImage
                image={block.desktopImage.localFile.childImageSharp.gatsbyImageData} 
                alt={block.desktopImage.altText} 
                className="d-none d-lg-block bg-image"
            />
            <ContentMaxStyled>
                {block.link ? (
                    <WPLink url={block.link.url} target={block.link.target} className="content">
                        <ContentBlock block={block} />
                    </WPLink>
                ) : (
                    <ContentBlock block={block} />
                )}

            </ContentMaxStyled>
            {block.includeImageCurve && 
                <Curve src={curve} alt="" fullWidthImage />
            }
        </ContainerWrap>
    )
}

export default FullWidthImage