import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import classNames from "classnames"
import styled from "styled-components"
import Calendar from "react-calendar"
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import DropdownWrap from "components/shared/dropdown/DropdownWrap"
import DropdownStyled from "components/shared/dropdown/DropdownStyled"
import DropdownToggle from "components/shared/dropdown/DropdownToggle"
import DropdownMenu from "components/shared/dropdown/DropdownMenu"
import DropdownItem from "components/shared/dropdown/DropdownItem"
import next from "images/arrow-right.svg"
import prev from "images/arrow-left.svg"
import iconDate from "images/icon-date.svg"
import iconTime from "images/icon-time.svg"

const FilterCount = styled.span`
    background: ${props => props.theme.colors.cyan};
    color: white;
    padding: 0 .25rem;
    display: inline-block;
`

const IconImage = styled(GatsbyImage)`
    min-width: 35px;
    flex-shrink: 0;
`

class ListingFilterDropdown extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false
        }


        const minDate = this.props.startDate.split(" ")
        const maxDate = this.props.endDate.split(" ")

        this.minDate = new Date(parseInt(minDate[0]), parseInt(minDate[1]) - 1, parseInt(minDate[2]))
        this.maxDate = new Date(parseInt(maxDate[0]), parseInt(maxDate[1]) - 1, parseInt(maxDate[2]))

        this.months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

        this.toggle = this.toggle.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside, false)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside, false)
    }

    handleClickOutside(e) {
        if (typeof window !== "undefined" && window.outerWidth > 991) {
            if (this.node && !this.node.contains(e.target)) {
                this.setState({
                    isOpen: false
                })
            }
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        const { type, last, filter } = this.props
        return(         
            <DropdownWrap ref={node => this.node = node} last={last}>
                <DropdownStyled>
                    <DropdownToggle
                        onClick={this.toggle}
                        className={classNames({ active: this.state.isOpen })}
                        imgIcon={filter === "selectedDateFilters" || filter === "selectedSessionFilters"}
                    >
                        {filter === "selectedDateFilters" &&
                            <img src={iconDate} alt="" className="imgIcon" />
                        }
                        {filter === "selectedSessionFilters" &&
                            <img src={iconTime} alt="" className="imgIcon" />
                        }
                        {this.props.filterText} 
                        <div className="d-flex">
                            {this.props.selectedFilters && this.props.selectedFilters.length > 0 &&
                                <FilterCount>{this.props.selectedFilters.length}</FilterCount>
                            }
                            <div>
                                <FontAwesomeIcon className="icon" icon={faAngleDown} />
                            </div>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu 
                        className={classNames({ active: this.state.isOpen })} 
                        as={type === "default" ? "ul" : "div"}
                        type={type}
                    >
                        {type === "default" ? (
                            <>
                                {this.props.filters && this.props.filters.map(filter => {
                                    if (filter.databaseId) {
                                        return(
                                            <DropdownItem  
                                                key={filter.databaseId} 
                                                className={classNames({
                                                    active: this.props.selectedFilters.includes(filter.databaseId) 
                                                })}
                                                onClick={() => this.props.setFilter(filter.databaseId, this.props.filter, this.props.inputType)}
                                            >
                                                <span className="checkbox"></span>
                                                {filter.acfCategoryIcon && filter.acfCategoryIcon.icon &&
                                                    <IconImage 
                                                        image={filter.acfCategoryIcon.icon.localFile.childImageSharp.gatsbyImageData}
                                                        alt={filter.acfCategoryIcon.icon.altText}
                                                        width={35}
                                                    />
                                                }
                                                <span>{filter.name}</span>
                                            </DropdownItem>
                                        )
                                    } return ""
                                })}
                            </>
                        ) : (
                            <Calendar
                                showNeighboringMonth={false}
                                onChange={(date) => this.props.setFilter(date, this.props.filter)}
                                value={this.props.selectedFilters !== "" ? this.props.selectedFilters : new Date()}
                                view="month"
                                nextLabel={<img src={next} alt="" width="8.5" />}
                                prevLabel={<img src={prev} alt="" width="8.5" />}
                                minDate={this.minDate}
                                maxDate={this.maxDate}
                                navigationLabel={({ date, label, locale, view }) => `${this.months[date.getMonth()]} ${date.getFullYear()}`}
                            />
                        )}
                    </DropdownMenu>
                </DropdownStyled>
            </DropdownWrap>
        )
    }
}

ListingFilterDropdown.defaultProps = {
    type: "default",
    inputType: "checkbox"
}


const ListingFilterDropdownExport = (props) => {
    return(
        <StaticQuery
            query={graphql`
                query {
                    allWp {
                        edges {
                            node {
                                ...optionsFragment
                            }
                        }
                    }
                }
            `}
            render={data => {
                if (
                    data.allWp.edges[0].node.options && 
                    data.allWp.edges[0].node.options.acfOptions.startDate && 
                    data.allWp.edges[0].node.options.acfOptions.endDate
                ) {
                    return (
                        <ListingFilterDropdown 
                            startDate={data.allWp.edges[0].node.options.acfOptions.startDate} 
                            endDate={data.allWp.edges[0].node.options.acfOptions.endDate} 
                            {...props} 
                        />
                    )
                } else return ""
            }}
        />
    )
}

export default ListingFilterDropdownExport