import React, { useEffect, useRef } from "react"
import Slider from "react-slick"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container } from "reactstrap"
import { media } from "utils/Media"
import classNames from "classnames"
import styled, { css } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import ContainerMax from "components/shared/ContainerMax"
import WPLink from "components/shared/WPLink"
import Icon from "components/shared/Icon"
import Text from "components/shared/Text"
import Button from "components/shared/Button"
import SnowflakeBg from "components/shared/SnowflakeBg"
import swipe from "images/swipe.svg"
import snowflakeImg from "images/snowflake-two-block.svg"
import { gsap } from 'utils/Gsap'
import { window } from 'browser-monads';


const ContainerMaxStyled = styled(ContainerMax)`
    padding-right: 0;
    padding-left: 15px;
    @media ${media.md} {
        padding-right: ${props => props.theme.bootstrapGutterWidth};
    }
`

const SliderStyled = styled(Slider)`
    overflow-y: visible;
    .slick-track {
        display: flex;
    }
    .slick-list {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
        padding-left: 0 !important;
        @media ${media.lg} {
            padding: 0;
        }
    }
    .slick-slide {
        padding: 0 15px;	
        height: auto;
    }
`

const SlideStyled = styled.div`
    position: relative;
    border-radius: .5rem;
    overflow: hidden;
    @media ${media.lg} {
        border-radius: 0;
    }
    ${props => props.second && css`
        @media ${media.md} {
            margin-top: 3rem;
        }
    `}
    .icon {
        position: absolute;
        top: .5rem;
        left: .5rem;
        z-index: 2;
        width: 80px;
        height: 80px;
        &--second {
            @media ${media.md} {
                top: 1rem;
            } 
        }
    }
    .content {
        position: absolute;
        z-index: 3;
        width: calc(100% - 2rem);
        left: 50%;
        bottom: 1rem;
        transform: translateX(-50%);
        background-color: white;
        padding: 1rem;
        border-radius: .5rem;
        text-decoration: none;
        @media ${media.lg} {
            width: calc(100% - 4rem);
            bottom: 2rem;
            padding: 2rem;
        }
        svg {
            font-size: 2.5rem;
            color: ${props => props.theme.colors.secondary};
            padding-left: 1rem;
            flex-grow: 1;
            width: 50px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1rem;
        }
        h3 {
            padding-left: 5px;
            padding-right: 50px;
            @media ${media.lg} {
                padding-right: 0;
            }
        }
    }
`

const TwoImageBlock = (props) => {
	const { block1, block2 } = props.block
    
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        lazyload: false,
        arrows: false,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '30px'
                }
            }
        ]
    }
    
    const leftBlock = useRef()
    const rightBlock = useRef()
    
    const lgScreens = window.matchMedia("(min-width: 992px)")
    useEffect(() => {
        if (lgScreens.matches) {
            gsap.from(leftBlock.current, {
                scrollTrigger : {
                    trigger: rightBlock.current,
                    start: "top bottom",
                    toggleActions: 'play reverse reverse play',
                    scrub: 0.5,
                },
                delay: 0,
                duration: 1,
                y: '150px'
            })
            gsap.from(rightBlock.current, {
                scrollTrigger : {
                    trigger: rightBlock.current,
                    start: "top 80%",
                    toggleActions: 'play reverse reverse play',
                    scrub: 0.5,
                },
                delay: 0,
                duration: 1,
                y: '-100px'
            })
        }
    }, [])


    return(
        <>
        <Container fluid className="px-0 position-relative overflow-shown">
            <ContainerMaxStyled className="py-4 py-xl-5">
                <SnowflakeBg src={snowflakeImg} alt="" />
                {/* <Snowflake src={snowflakeGreen} alt="" right /> */}
                {/* <img src={snowflakeBg} alt="" /> */}
                <SliderStyled {...settings}   >
                    <Slide block={block1} ref={leftBlock} />
                    <Slide block={block2} second ref={rightBlock}  />
                </SliderStyled>
                <div className="text-center d-md-none">
                    <img src={swipe} alt="Swipe to see more" width="100" />
                </div>
            </ContainerMaxStyled>
        </Container>
        </>
    )
}

export default TwoImageBlock

const Slide = React.forwardRef((props, ref) => {
    const { block } = props
    return(
        <SlideStyled second={props.second} ref={ref}>
            {block.icon && 
                <Icon 
                    className={classNames({
                        "icon": true,
                        "icon--second": props.second
                    })}
                    icon={block.icon}
                />
            }

            {block.image &&
                <GatsbyImage 
                    image={block.image.localFile.childImageSharp.gatsbyImageData} 
                    alt={block.image.altText} 
                />
            }
            <WPLink url={block.link.url} target={block.link.target} className="content">
                {block.title &&
                    <Text as="h3" title smaller className="pb-lg-3">
                        <span>{block.title}</span> 
                        <span className="text-right">
                            <FontAwesomeIcon icon={faChevronRight} className="d-lg-none" />
                        </span>
                    </Text>
                }
                <Button as="div" color="secondary" className="d-none d-lg-inline-block">
                    {block.link.title}
                </Button>
            </WPLink>
        </SlideStyled>
    )
})