import React from "react"
import { Row, Col } from "reactstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"
import styled from "styled-components"
import ContainerMax from "components/shared/ContainerMax"
import { LinkSearchReplace } from "utils/LinkSearchReplace"
import TextBlockStyles from "components/shared/TextBlockStyles"
import Text from "components/shared/Text"

const TextStyled = styled.div`
    p {
        font-size: ${props => props.theme.font.size.base};
    }
`

const TextBlockFullWidth = (props) => {
    let { text, title } = props.block

    if (text) {
        // Wrap iframes with responsive div
        text = text.replace('<iframe', '<div class="embed-responsive embed-responsive-16by9"><iframe')
        text = text.replace('</iframe>', '</iframe></div>')

        let size = 12
        if (props.block.width === "narrow" && props.block.icon) size = 10
        else if (props.block.icon) size = 11
        else if (props.block.width === "narrow") size = 10

        return (
            <ContainerMax
                maxWidth="1300"
                className={classNames({
                    "justify-content-center": true,
                    "py-4 py-xl-5" : props.block.padding === "yes"
                })}
            >
                <Row>
                    {props.block.icon &&
                        <Col md={1} className="d-none d-md-block">
                            <GatsbyImage
                                image={props.block.icon.localFile.childImageSharp.gatsbyImageData}
                                alt={props.block.icon.altText}
                            />
                        </Col>
                    }
                    <Col
                        xs={12}
                        md={size}
                    >
                        {title && 
                            <Text as="h2" title className="mb-3">{title}</Text>
                        }
                        <TextBlockStyles>
                            <TextStyled dangerouslySetInnerHTML={{ __html: LinkSearchReplace(text) }} />
                        </TextBlockStyles>
                    </Col>
                </Row>
            </ContainerMax>
        )
    } else {
        return null
    }
}

export default TextBlockFullWidth