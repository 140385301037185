import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import { Row, Col } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import classNames from "classnames"
import styled from "styled-components"
import { media } from "utils/Media"
import ContainerMax from "components/shared/ContainerMax"
import ContentMax from "components/shared/ContentMax"
import NewsPreview from "components/shared/NewsPreview"
import DropdownWrap from "components/shared/dropdown/DropdownWrap"
import DropdownStyled from "components/shared/dropdown/DropdownStyled"
import DropdownToggle from "components/shared/dropdown/DropdownToggle"
import DropdownMenu from "components/shared/dropdown/DropdownMenu"
import DropdownItem from "components/shared/dropdown/DropdownItem"
import Button from "components/shared/Button"
import FilterDropdown from "components/shared/FilterDropdown"
import FilterBar from "components/shared/FilterBar"
import filter from "images/icon-filters.svg"

const NewsListingWrap = styled.div`
	article {
		margin-bottom: 30px;

  		p.date {
            font-size: ${props => props.theme.font.size.sm};
            color: ${props => props.theme.colors.black};
            margin: 0;
            display: none;

            @media ${media.md} {
                display: block;
            }
  		}

  		p.more {
  			text-transform: uppercase;
		    line-height: 1;
		    margin-top: 1rem;
            
		    a {
				font-family: ${props => props.theme.font.family.bold};
				font-size: ${props => props.theme.font.size.sm};
				color: ${props => props.theme.colors.primary};
				text-decoration: none;
		    }
  		}
	}
	.view-more {
	    text-align: center;
	    margin-top: 10px;
	    button {
	    	appearance: none;
	    	background: #fff;
	    	border: solid 1px #323741;
	    	height: 50px;
			line-height: 50px;
			padding: 0 40px;
			font-size: 0.875rem;/*14*/
		    font-family: ${props => props.theme.font.family.bold};
  			color: #323741;
  			text-transform: uppercase;
	    }
	}
`

const FilterTrigger = styled.button`
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    border: 0;
    text-align: center;
    font-weight: ${props => props.theme.font.weight.bold};
    margin-bottom: 1.5rem;
    padding: .75rem;

    @media ${media.lg} {
        display: none;
    }
`


const postsPerPage = 24;
class News extends Component {	

    constructor(props) {
        super(props);
        this.state = {
            paginationLimit: postsPerPage,
            filter: 0,
            filterText: "All",
            isOpen: false
        }

        this.toggle = this.toggle.bind(this)
        this.triggerFilterOverlay = this.triggerFilterOverlay.bind(this)
        this.resetFilters = this.resetFilters.bind(this)
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    triggerFilterOverlay() {
        this.setState({
            active: !this.state.active
        })
        document.body.classList.toggle('dropdown-filter-active')
    }
  
    resetFilters() {
        this.setState({
            filter: 0,
            filterText: "All"
        })
    }
  
    /**
     * Load the next page of articles
     */
    loadMore = (e) => {
        e.preventDefault()
        this.setState({
            paginationLimit: (this.state.paginationLimit+postsPerPage)
        })
    }
  
    /**
     * set the filter
     */
    setFilter = (databaseId, name) => {
        if (this.state.filter === databaseId) {
            this.setState({
                filter: 0,
                filterText: "All"
            })
        } else {
            this.setState({
                filter: databaseId,
                filterText: name
            })
        }
    }

    //, sort: { fields: [menuOrder], order: ASC }
    
    render() {
        return (
            <StaticQuery
                query={graphql`
                    {
                        allWpNewsArticle(limit: 1000, filter: {status: {eq: "publish"}}) {
                            edges {
                                node {
                                    uri
                                    title
                                    date
                                    acfNews {
                                        hidePublishDate
                                        newsYoutube
                                        featuredImage45 {
                                            altText
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(width: 545, quality: 85, placeholder: NONE, layout: CONSTRAINED)
                                                }
                                            }
                                        }
                                    }
                                    newsCategories {
                                        nodes {
                                            name
                                            databaseId
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}
                render={data => {
                    let posts = data.allWpNewsArticle.edges
                    var printedArticles = 0
                    let categories = []

                    //set categories for dropdown
                    posts && posts.forEach(({node}) => {
                        node.newsCategories && node.newsCategories.nodes.forEach(cat => {
                            categories[cat.databaseId] = cat
                        })
                    })

                    //filter posts by
                    if (this.state.filter !== 0) {
                        posts = posts && posts.filter(({node}) => {
                            const postCategories = node.newsCategories && node.newsCategories.nodes
                            return postCategories && postCategories.find(node => this.state.filter === node.databaseId)
                        })
                    }

                    // console.log(posts)
                
                    return (
                        <ContainerMax className="py-4">
                            <ContentMax>
                                <FilterTrigger onClick={this.triggerFilterOverlay}>
                                    FILTERS <img src={filter} alt="" className="pl-3" />
                                </FilterTrigger>
                                <NewsListingWrap>
                                <FilterBar className={classNames({
                                    active: this.state.active
                                })}>
                                        <div className="d-none d-md-inline-block"><strong>Filter by: </strong>&nbsp;</div>
                                        <DropdownWrap>
                                            <DropdownStyled>
                                                <DropdownToggle
                                                    onClick={this.toggle}
                                                    className={classNames({ active: this.state.isOpen })}
                                                >
                                                    {this.state.filterText} 
                                                    <div>
                                                        <FontAwesomeIcon className="icon" icon={faAngleDown} />
                                                    </div>
                                                </DropdownToggle>
                                                <DropdownMenu 
                                                    className={classNames({ active: this.state.isOpen })} 
                                                >
                                                    {/* <DropdownItem onClick={() => this.setFilter(0, "All")}>
                                                        <span className="checkbox"></span>
                                                        <span>All</span>
                                                    </DropdownItem> */}
                                                    {categories && categories.map((cat, i) => {
                                                        return(
                                                            <DropdownItem 
                                                                key={i} 
                                                                onClick={() => this.setFilter(cat.databaseId, cat.name)}
                                                                className={classNames({
                                                                    active: this.state.filter === cat.databaseId 
                                                                })}
                                                            >
                                                                <span className="checkbox"></span>
                                                                <span>{cat.name}</span>
                                                            </DropdownItem>
                                                        )
                                                    })}
                                                </DropdownMenu>
                                            </DropdownStyled>
                                        </DropdownWrap>
                                        <div className="d-lg-none d-flex justify-content-between filter-buttons">
                                            <Button onClick={this.resetFilters} color="grey">
                                                Clear
                                            </Button>
                                            <Button color="secondary" onClick={this.triggerFilterOverlay}>
                                                Apply
                                            </Button>
                                        </div>
                                    </FilterBar>
                                
                                    <Row>
                                        {posts && posts.map((post, i) => {
                                            const articleType = post.node.acfNews.newsYoutube ? "video" : "news"
  
                                            if (this.state.paginationLimit > printedArticles) {
                                                printedArticles++;
                                                return (
                                                    <Col xs={12} sm={6} md={4} lg={3} key={i} className={`${articleType}`}>
                                                        <NewsPreview 
                                                            data={post} 
                                                            type="NewsListing"
                                                        />
                                                    </Col>
                                                )
                                            }
                                            return null;
                                        })}
                                    </Row>
                                
                                    <div className="view-more">
                                        {/*do we want to display the 'view more' button?*/}
                                        {this.state.paginationLimit < posts.length &&
                                            <Button href="#" onClick={(e) => this.loadMore(e)} color="black">
                                                <span>View More</span>
                                            </Button>
                                        }
                                    </div>
                                </NewsListingWrap>
                            </ContentMax>
                        </ContainerMax> 
                    )
                }}
            />
        )   
    }
}

export default News