import React from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import ContainerMax from "components/shared/ContainerMax"
import TextBlockStyles from "components/shared/TextBlockStyles"
import { LinkSearchReplace } from "utils/LinkSearchReplace"
import { media } from "utils/Media"
import apple from "images/apple.png"
import google from "images/google.png"

const AppleImg = styled.img`
  width: 85%;
  @media ${media.sm} {
    width: auto;
    max-width: 200px;
  }
`

const GoogleImg = styled.img`
  @media ${media.sm} {
    max-width: 245px;
  }
`

const App = ({block}) => {
  const {
    displayOnDesktop,
    text,
    appleLink,
    googleLink
  } = block


  return(
    <ContainerMax
      maxWidth="1300"
      className={`${displayOnDesktop ? "" : "d-md-none"} pt-4 pb-5`}
    >
      {text &&
        <TextBlockStyles>
          <div dangerouslySetInnerHTML={{ __html: LinkSearchReplace(text) }} className="pb-4" />
        </TextBlockStyles>
      }
      <Row className="justify-content-between justify-content-md-center">
        {appleLink &&
          <Col xs={6} md="auto">
            <a href={appleLink} target="_blank" rel="noopener noreferrer" className="d-block">
              <AppleImg src={apple} alt="Apple app store" />
            </a>
          </Col>
        }
        {googleLink &&
          <Col xs={6} md="auto">
            <a href={googleLink} target="_blank" rel="noopener noreferrer" className="d-block">
              <GoogleImg src={google} alt="Google play store" />
            </a>
          </Col>
        }
      </Row>
    </ContainerMax>
  )
}

export default App