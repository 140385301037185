import React, { useEffect } from "react"
import ContainerMax from "components/shared/ContainerMax"

export default function SocialWall({block}) {
  const scripts = extractScriptSrcUrls(block.embedCode)

  useEffect(() => {
    scripts.forEach(script => {
      const tag = document.createElement('script')
      tag.async = true
      tag.src = script
      document.body.appendChild(tag)
    })
  }, [])

  function removeScriptTags(html) {
    // Use a regular expression to match <script> tags and their contents
    const scriptTagRegex = /<script\b[^>]*>[\s\S]*?<\/script>/gi;
  
    // Use the replace method to remove the matched <script> tags
    const cleanedHtml = html.replace(scriptTagRegex, '');
  
    return cleanedHtml;
  }

  function extractScriptSrcUrls(html) {
    // Use a regular expression to match <script> tags and capture their src attribute values
    const scriptSrcRegex = /<script[^>]+src=['"]([^'"]+)['"][^>]*>/gi;
    const srcUrls = [];
  
    // Use a loop to find and capture the src URLs
    let match;
    while ((match = scriptSrcRegex.exec(html)) !== null) {
      const srcUrl = match[1];
      srcUrls.push(srcUrl);
    }
  
    return srcUrls;
  }

  if (block.embedCode) {

    const div = removeScriptTags(block.embedCode)

    return(
      <ContainerMax
        maxWidth="1440"
        className="py-4"
        flocker
        dangerouslySetInnerHTML={{ __html: div }}
      />
    )
  } else return null
}