import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import { Row } from "reactstrap"
import styled from "styled-components"
import queryString from "query-string"
import ContainerMax from "components/shared/ContainerMax"
import Text from "components/shared/Text"
import ListingItem from "./ListingItem"
import ListingFilterBar from "./ListingFilterBar"
import snowflakes from "images/snowflakes-ttd.svg"

const Bg = styled.div`
    background-image: url("${snowflakes}");
    background-size: 100%;
    background-position: 0 5rem;
    background-repeat: repeat-y;
`

class Listing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listingData: [],
            selectedDateFilters: "",
            selectedSessionFilters: [],
            selectedAgeFilters: [],
            selectedCategoryFilters: [],
            dateFilters: [],
            sessionFilters: [],
            ageFilters: [],
            categoryFilters: [],
            ajsId: "",
            loaded: false
        }

        this.setFilters = this.setFilters.bind(this)
        this.resetFilters = this.resetFilters.bind(this)
    }

    componentDidMount() {
        let listingData = this.props.data.allWpThingtodo.edges
        let sessionFilters = []
        let ageFilters = []
        let categoryFilters = []
        let sessionFiltersTemp = []
        let ageFiltersTemp = []
        let categoryFiltersTemp = []
        let loaded

        // remove shows that are selected as hidden from listing
        listingData = listingData.filter(({node}) => {
            return node.acfThingsToDo.showInListing !== "no"
        })

        // create unique array of filters that have been tagged
        // its unique as the key is the databaseId so any dubs would get overwritten
        // also prevents any unused tags from being displayed on frontend
        listingData.forEach(({node}) => {
            node["ttdSessions"] && node["ttdSessions"].nodes && node["ttdSessions"].nodes.forEach(node => { sessionFilters[node.databaseId] = node })
            node["ttdAges"] && node["ttdAges"].nodes && node["ttdAges"].nodes.forEach(node => { ageFilters[node.databaseId] = node })
            node["ttdCategories"] && node["ttdCategories"].nodes && node["ttdCategories"].nodes.forEach(node => { categoryFilters[node.databaseId] = node })
        })

        // Get correct order of categories
        this.props.data["ttdSessions"] && this.props.data["ttdSessions"].edges.forEach(({node}) => {
            if (sessionFilters[node.databaseId]) sessionFiltersTemp.push(sessionFilters[node.databaseId])
        })

        this.props.data["ttdAges"] && this.props.data["ttdAges"].edges.forEach(({node}) => {
            if (ageFilters[node.databaseId]) ageFiltersTemp.push(ageFilters[node.databaseId])
        })

        this.props.data["ttdCategories"] && this.props.data["ttdCategories"].edges.forEach(({node}) => {
            if (categoryFilters[node.databaseId]) categoryFiltersTemp.push(categoryFilters[node.databaseId])
        })

        // overide with new order
        sessionFilters = sessionFiltersTemp
        ageFilters = ageFiltersTemp
        categoryFilters = categoryFiltersTemp
        loaded = true

        let state = {
            listingData,
            sessionFilters,
            ageFilters,
            categoryFilters,
            loaded
        }

        // get query string, check if landing on page with predefined filters
        const urlString = typeof window !== 'undefined' ? window.location.search : ''

        // parse query string into json
        const selectedFilters = queryString.parse(urlString)
        
        // Do we have any filters in the url?
        if (Object.keys(selectedFilters).length !== 0) {
            // loop filters and set selectedFilters in state 
            for (const [key, value] of Object.entries(selectedFilters)) {
                if (key === "selectedDateFilters") {
                    state[key] = new Date(value)
                } else {
                    let arr = []
                    arr = value.split(',')
                    arr = arr.map((x) => { 
                        return parseInt(x)
                    })
                    state[key] = arr
                    // state[key] = value.split(',')
                }
            }
        }

        // listingData array in state wont change going forwards but filtered in render() below
        this.setState(state)

        // for tracking link
        this.setAjsId()
    }

    setAjsId() {
        if (window.analytics && typeof window.analytics.user !== "undefined") {
            this.setState({
                ajsId: window.analytics.user().anonymousId()
            })
        }
    }

    setFilters(selectedFilters, filter) {
        this.setState({
            [filter]: selectedFilters
        })
    }

    resetFilters() {
        this.setState({
            selectedDateFilters: "",
            selectedSessionFilters: [],
            selectedAgeFilters: [],
            selectedCategoryFilters: []
        })

        // remove any query string filters
        if (window.history.replaceState) {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname
            window.history.replaceState({ path: newurl }, '', newurl)
        }
    }

    render() {
        let { 
            listingData, 
            selectedDateFilters,
            selectedSessionFilters, 
            selectedAgeFilters, 
            selectedCategoryFilters, 
            dateFilters, 
            sessionFilters, 
            ageFilters, 
            categoryFilters, 
            loaded
        } = this.state

 
        // by default all items are available on all dates
        // items have the ability in CMS to be listed as unavailable for a date and session
        if (selectedDateFilters !== "") {
            listingData = listingData && listingData.filter(({node}) => {
                const { sessionsNotAvailable } = node.acfThingsToDo

                let returnItem = true

                sessionsNotAvailable && sessionsNotAvailable.forEach(session => {
                    // does the date match the seleceted filter date?
                    if (new Date(session.date).getTime() === selectedDateFilters.getTime()) {
                        if (session.session.length === 3) {
                            // item has all sessions selected as unavailable (3 sessions)
                            // user has selected a date but no session filters, this doesnt matter as no sessions are available anyway
                            returnItem = false
                        } else if (selectedSessionFilters.length > 0) {
                            session.session.forEach(sessionId => {
                                if (selectedSessionFilters.includes(parseInt(sessionId))) {
                                    returnItem = false
                                }
                            })
                        }

                    }
                })

                return returnItem
            })
        }

        // console.log(listingData)

        // filter items by session
        if (selectedSessionFilters.length > 0) {
            listingData = listingData && listingData.filter(({node}) => {
                const nodeCategories = node["ttdSessions"] && node["ttdSessions"].nodes
                return nodeCategories && nodeCategories.find(node => selectedSessionFilters.includes(node.databaseId))
            })
        }

        // filter items by ages
        if (selectedAgeFilters.length > 0) {
            listingData = listingData && listingData.filter(({node}) => {
                const nodeCategories = node["ttdAges"] && node["ttdAges"].nodes
                return nodeCategories && nodeCategories.find(node => selectedAgeFilters.includes(node.databaseId))
            })
        }

        // filter items by category
        if (selectedCategoryFilters.length > 0) {
            listingData = listingData && listingData.filter(({node}) => {
                const nodeCategories = node["ttdCategories"] && node["ttdCategories"].nodes
                return nodeCategories && nodeCategories.find(node => selectedCategoryFilters.includes(node.databaseId))
            })
        }

        const items = listingData && listingData.map(({node}, i) => {
            const featured = node.acfThingsToDo.featured
            return <ListingItem key={i} data={node} featured={featured} ajsId={this.state.ajsId} />
        })
        
        return(  
            <Bg>
                <ContainerMax className="py-4">
                    <ListingFilterBar
                        dateFilters={dateFilters}
                        sessionFilters={sessionFilters}
                        ageFilters={ageFilters}
                        categoryFilters={categoryFilters}
                        selectedDateFilters={selectedDateFilters}
                        selectedSessionFilters={selectedSessionFilters}
                        selectedAgeFilters={selectedAgeFilters}
                        selectedCategoryFilters={selectedCategoryFilters}
                        setFilters={this.setFilters}
                        resetFilters={this.resetFilters}
                    />
                     
                    {items.length > 0 ? (
                        <Row>
                            {items}
                        </Row>
                    ) : (
                        <div className="py-5">
                            {loaded && 
                                <>
                                    <Text condensed xl secondary center>WE’RE NOT FINDING ANYTHING THAT’S A PERFECT MATCH</Text>
                                    <Text center className="pb-5">Try adjusting your filters a bit…</Text>
                                </>
                            }
                        </div>
                    )}
                </ContainerMax>
            </Bg>
        )
    }
}

const ListingExport = (props) => (
    <StaticQuery
        query={graphql`
            query {
                allWpThingtodo(
                    limit: 100
                    filter: {status: {eq: "publish"}}
                    sort: { fields: [menuOrder], order: ASC }
                  ) {
                    edges {
                        node {
                            ...thingtodoFragment
                        }
                    }
                }
                ttdSessions: allWpTtdSession {
                    edges {
                        node {
                            databaseId
                            name
                        }
                    }
                }
                ttdAges: allWpTtdAge {
                    edges {
                        node {
                            databaseId
                            name
                        }
                    }
                }
                ttdCategories: allWpTtdCategory {
                    edges {
                        node {
                            databaseId
                            name
                        }
                    }
                }
            }
        `}
        render={data => {
            return (
                <Listing 
                    block={props.block}
                    data={data} 
                />
            )
        }}
    />
)

export default ListingExport