import React, { Component } from "react"
// import { DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import ContainerMax from "components/shared/ContainerMax"
import GalleryGrid from "components/shared/GalleryGrid"
// import BlockTitle from "components/shared/BlockTitle"
import FilterBar from "components/shared/FilterBar"
import FilterDropdown from "components/shared/FilterDropdown"

import DropdownWrap from "components/shared/dropdown/DropdownWrap"
import DropdownStyled from "components/shared/dropdown/DropdownStyled"
import DropdownToggle from "components/shared/dropdown/DropdownToggle"
import DropdownMenu from "components/shared/dropdown/DropdownMenu"
import DropdownItem from "components/shared/dropdown/DropdownItem"

class Galleries extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedGallery: "",
            filterText: "SELECT (ALL)",
            images: []
        }

        this.toggle = this.toggle.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        this.setImages("SELECT (ALL)")
        // document.addEventListener("mousedown", this.handleClickOutside, false)
    }

    // componentWillUnmount() {
    //     document.removeEventListener("mousedown", this.handleClickOutside, false)
    // }

    setImages(id) {
        let images = []

        if (id === "SELECT (ALL)") {
            this.props.block.galleries.forEach(gallery => {
                images.push(gallery.acfGalleries.images)
            })
        } else {
            images = this.props.block.galleries[id].acfGalleries.images
        }

        images = images.flat()

        this.setState({
            images
        })
    }

    setFilter(id, text) {
        this.setState({
            filter: id,
            filterText: text
        })

        const filter = id !== "" ? id : text

        this.setImages(filter)
    }

    handleClickOutside(e) {
        if (typeof window !== "undefined" && window.outerWidth > 991) {
            if (this.node && !this.node.contains(e.target)) {
                this.setState({
                    isOpen: false
                })
            }
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        const { block } = this.props
        const  { galleries } = block

        const DropDown = (props) => {
            return(
                <FilterDropdown {...props}>
                    <DropdownWrap ref={node => this.node = node}>
                        <DropdownStyled>
                            <DropdownToggle
                                onClick={this.toggle}
                                className={classNames({ active: this.state.isOpen })}
                            >
                                Select gallery
                                <FontAwesomeIcon icon={faAngleDown} />
                            </DropdownToggle>
                            
                            <DropdownMenu className={classNames({ active: this.state.isOpen })} >
                                <DropdownItem 
                                    onClick={() => this.setFilter("", "SELECT (ALL)")}
                                    className={classNames({
                                        active: this.state.filterText === "SELECT (ALL)"
                                    })}
                                >
                                    <span className="checkbox"></span>
                                    View (ALL)
                                </DropdownItem>
                                {galleries && galleries.map((gallery, i) => {
                                    // if (this.state.filterText !== gallery.title) {
                                        return (
                                            <DropdownItem 
                                                key={i}
                                                onClick={() => this.setFilter(i, gallery.title)}
                                                className={classNames({
                                                    active: this.state.filterText === gallery.title
                                                })}
                                            >
                                                <span className="checkbox"></span>
                                                {gallery.title}
                                            </DropdownItem>
                                        )
                                    // } else return ""
                                })}
                            </DropdownMenu>
                        </DropdownStyled>
                    </DropdownWrap>
                </FilterDropdown>
            )
        }

        return(
            <ContainerMax className="my-4" maxWidth="1600">
                {block.title && 
                    <h3>  
                        {block.title}
                    </h3>
                }
                {galleries.length > 1 &&
                    <>
                        <FilterBar displayMobile>
                            <div className="filter-title">Filter by</div>
                            <DropDown />
                        </FilterBar>
                    </>
                }
                <GalleryGrid images={this.state.images} />
            </ContainerMax>
        )
    }
}

export default Galleries