import React from "react"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { LinkSearchReplace } from "utils/LinkSearchReplace"
import TextBlockStyles from "components/shared/TextBlockStyles"

const Border = styled.div`
  border: 3px solid ${props => props.theme.colors.primary};
  padding: 2rem;
  border-radius: 4px;
`

const TextStyled = styled(TextBlockStyles)`
  h1, h2, h3,h4 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.secondary};
  }
`

const TwoColumnTextWithBorders = (props) => {
  const  { text1, text2 } = props.block
  return(
    <Container className="py-5">
      <Row>
        {text1 &&
          <Col xs={12} md={6} className="mb-4 mb-md-0">
            <Border>
              <TextStyled dangerouslySetInnerHTML={{ __html: LinkSearchReplace(text1) }} />
            </Border>
          </Col>
        }
        {text2 &&
          <Col xs={12} md={6}>
            <Border>
              <TextStyled dangerouslySetInnerHTML={{ __html: LinkSearchReplace(text2) }} />
            </Border>
          </Col>
        }
      </Row>
    </Container>
  )
}

export default TwoColumnTextWithBorders