import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalBody, Row, Col } from "reactstrap";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { faGlobe, faTimes } from "@fortawesome/free-solid-svg-icons";
import { media } from "utils/Media";
import Text from "components/shared/Text";
import PopupGalleryCarousel from "components/shared/PopupGalleryCarousel";
import WPLink from "components/shared/WPLink";

const Close = styled.button`
  background: transparent;
  border: 0;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  display: block;
  color: ${(props) => props.theme.colors.black};
  font-size: 1.5rem;

  @media ${media.md} {
    right: -0.5rem;
    top: -0.75rem;
  }
  @media ${media.lg} {
    right: -1.7rem;
    top: -1.85rem;
  }
`;

const SocialLinks = styled.div`
  div {
    margin-bottom: 0.5rem;
  }

  .social {
    display: flex;
    align-items: center;

    a {
      /* width: 195px;
      max-width: 195px; */
      display: flex;
      /* justify-content: space-between; */
      div {
        width: 40px;
      }
      svg {
        font-size: 1.4rem;
        max-height: 25px;
        max-width: 25px;
        color: ${(props) => props.theme.colors.secondary};
      }
    }
  }
`;

const FoodType = styled.span`
  position: relative;
  padding: 0.25rem 0.75rem;
  margin: 0 0.5rem 0.5rem 0;
  display: inline-block;
  background-color: ${(props) => props.$bgcolor};
  border-radius: 15px;

  span {
    color: ${(props) => props.$color};
    position: relative;
    z-index: 1;
  }
`;

const Popup = (props) => {
  const { data } = props;
  const {
    logo,
    popupText,
    popupCtaLink,
    popupFacebook,
    popupFacebookText,
    popupTwitter,
    popupTwitterText,
    popupInstagram,
    popupInstagramText,
    popupWebsiteLink,
    galleryOrSingleImage,
    popupGallery,
    popupSingleImage,
  } = data?.acfListingPageSettings;
  const { title, sfFoodtype } = data;
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} centered size="xl">
      <ModalBody className="p-4">
        <Close onClick={props.toggle}>
          <FontAwesomeIcon icon={faTimes} />
          <span className="sr-only">Close</span>
        </Close>
        <Row>
          <Col lg={{ size: 4 }}>
            <Row className="align-items-center">
              <Col xs={12} className="pb-3 pb-lg-0">
                {title && (
                  <Text
                    $uppercase
                    $bold
                    $navy
                    xl
                    center
                    className="mb-4 d-lg-none"
                  >
                    {title}
                  </Text>
                )}
                {popupText && (
                  <Text
                    className="d-lg-none"
                    base
                    dangerouslySetInnerHTML={{ __html: popupText }}
                  />
                )}
                {logo && (
                  <div className="mb-3 d-none d-lg-block">
                    <GatsbyImage
                      image={logo.localFile.childImageSharp.gatsbyImageData}
                      alt={logo.altText}
                    />
                  </div>
                )}
                {title && (
                  <Text
                    $uppercase
                    $bold
                    $navy
                    className="mb-4 d-none d-lg-block"
                  >
                    {title}
                  </Text>
                )}
                <SocialLinks>
                  {popupFacebook && (
                    <div className="social d-none d-lg-flex">
                      <a
                        href={popupFacebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <span className="sr-only">Facebook</span>
                          <FontAwesomeIcon icon={faFacebookF} />
                        </div>
                      {popupFacebookText && (
                        <Text $navy $bold>
                          {popupFacebookText}
                        </Text>
                      )}
                      </a>
                    </div>
                  )}
                  {popupTwitter && (
                    <div className="social d-none d-lg-flex">
                      <a
                        href={popupTwitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <span className="sr-only">Twitter</span>
                          <FontAwesomeIcon icon={faTwitter} />
                        </div>
                      {popupTwitterText && (
                        <Text $navy $bold>
                          {popupTwitterText}
                        </Text>
                      )}
                      </a>
                    </div>
                  )}
                  {popupInstagram && (
                    <div className="social d-none d-lg-flex">
                      <a
                        href={popupInstagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <span className="sr-only">Instagram</span>
                          <FontAwesomeIcon icon={faInstagram} />
                        </div>
                      {popupInstagramText && (
                        <Text $navy $bold>
                          {popupInstagramText}
                        </Text>
                      )}
                      </a>
                    </div>
                  )}
                  {popupWebsiteLink && (
                    <div className="social">
                      <WPLink
                        url={popupWebsiteLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <span className="sr-only">Website</span>
                          <FontAwesomeIcon icon={faGlobe} />
                        </div>
                        <Text $navy $bold>
                          {popupWebsiteLink}
                        </Text>
                      </WPLink>
                    </div>
                  )}
                </SocialLinks>

                {sfFoodtype?.nodes?.length > 0 && (
                  <div className="pt-2 pb-4">
                    {sfFoodtype?.nodes?.map((type, i) => {
                      return (
                        <FoodType
                          key={`foodType${i}`}
                          $color={type.acfFoodType?.colour}
                          $bgcolor={type.acfFoodType?.backgroundColour}
                        >
                          <span>{type.name}</span>
                        </FoodType>
                      );
                    })}
                  </div>
                )}
                {popupCtaLink && (
                  <WPLink
                    url={popupCtaLink.url}
                    button
                    color="secondary"
                    target={popupCtaLink.target}
                  >
                    {popupCtaLink.title}
                  </WPLink>
                )}
              </Col>
            </Row>
          </Col>
          <Col lg={{ size: 8 }}>
            {galleryOrSingleImage === "single" ? (
              <>
                {popupSingleImage && (
                  <GatsbyImage
                    image={
                      popupSingleImage.localFile.childImageSharp.gatsbyImageData
                    }
                    alt={popupSingleImage.altText}
                    className="d-none d-lg-block"
                  />
                )}
              </>
            ) : (
              <>
                {popupGallery && (
                  <div className="pt-3 pt-md-0">
                    <PopupGalleryCarousel images={popupGallery} />
                  </div>
                )}
              </>
            )}
          </Col>
          <Col xs={12} className="pt-4">
            {popupText && (
              <Text
                className="d-none d-lg-block"
                base
                dangerouslySetInnerHTML={{ __html: popupText }}
              />
            )}
            {logo && (
              <div className="mb-3 d-lg-none">
                <GatsbyImage
                  image={logo.localFile.childImageSharp.gatsbyImageData}
                  alt={logo.altText}
                />
              </div>
            )}
            <SocialLinks>
              {popupFacebook && (
                <div className="social d-lg-none">
                  <a
                    href={popupFacebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">Facebook</span>
                    <FontAwesomeIcon icon={faFacebookF} />
                  {popupFacebookText && (
                    <Text $navy $bold>
                      {popupFacebookText}
                    </Text>
                  )}
                  </a>
                </div>
              )}
              {popupTwitter && (
                <div className="social d-lg-none">
                  <a
                    href={popupTwitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">Twitter</span>
                    <FontAwesomeIcon icon={faTwitter} />
                  {popupTwitterText && (
                    <Text $navy $bold>
                      {popupTwitterText}
                    </Text>
                  )}
                  </a>
                </div>
              )}
              {popupInstagram && (
                <div className="social d-lg-none">
                  <a
                    href={popupInstagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">Instagram</span>
                    <FontAwesomeIcon icon={faInstagram} />
                  {popupInstagramText && (
                    <Text $navy $bold>
                      {popupInstagramText}
                    </Text>
                  )}
                  </a>
                </div>
              )}
            </SocialLinks>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default Popup;
