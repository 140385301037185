/**
 * Preview of a news article
 * Used on news index and homepage news carosuel
 */
 import React, { useState } from "react"
 import { Link } from "gatsby"
 import styled from "styled-components"
 import { GatsbyImage } from "gatsby-plugin-image"
 import { media } from "utils/Media"
 import VideoOverlay from "components/shared/VideoOverlay"
 import DateFormat from "components/shared/DateFormat"
//  import CarouselItemBg from "components/shared/CarouselItemBg"
 import Play from "images/play-icon.svg"
 
 const ArticleStyled = styled.article`
     margin-bottom: 0;		
     width: 100%;
     display: block;
     position: relative;
 
     > a {
         line-height: 0;
         display: block;
     }
 
     .news-video {
         line-height: 0;
         cursor: pointer;
 
         .news-play {
             width: 60px;
             height: 60px;
             background-color: ${props => props.theme.colors.secondary};
             border-radius: 50%;
             position: relative;
 
             img {
                 position: absolute;
                 top: 50%;
                 left: 50%;
                 transform: translate(-50%, -50%);
             }
         }
     }
 
     .gatsby-image-wrapper {
         -webkit-transform: translate3d(0,0,0);
     }
 
     .news-content {
         position: absolute;
         padding: 1rem;
         bottom: 1rem;
         left: 1rem;
         width: calc(100% - 2rem);
         background-color: white;
         color: black;
         line-height: 1.1;
         z-index: 10;
         border-radius: 4px;
         -webkit-backface-visibility: hidden;
         -webkit-transform: translate3d(0,0,0);
 
         h2 {
             font-size: ${props => props.theme.font.size.base};
             font-family: ${props => props.theme.font.family.condensed};
             font-weight: ${props => props.theme.font.weight.bold};
             color: black;
             text-transform: uppercase;
             margin: 0;
 
             @media ${media.md} {
                 font-size: ${props => props.theme.font.size.lg};
             }
         }
 
         .date {
             font-size: ${props => props.theme.font.size.sm};
             font-family: ${props => props.theme.font.family.regular};
             font-weight: ${props => props.theme.font.weight.regular};
             margin: 0;
             padding-top: .25rem;
         }
 
         .more {  
             font-size: ${props => props.theme.font.size.sm};
             color: ${props => props.theme.colors.primary};
             font-family: ${props => props.theme.font.family.bold};
             padding-top: .25rem;
 
             @media ${media.md} {
                 font-size: ${props => props.theme.font.size.base};
             }
         }
     }
 
    &:hover {
        .hoverBg {
            opacity: 1;
            /* z-index: 11; */

            &.video {
                opacity: .8;
                background-image: ${props => props.theme.colors.gradient};
                background-color: transparent;
            }
        }
    }
 `

const ItemBg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #000089 0%, #586BB8 100%);
        opacity: 0.8;
    }

    div {
        position: absolute;
        z-index: 2;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${props => props.theme.colors.primary};
        padding: 1rem 1.5rem;
        border: 2px solid ${props => props.theme.colors.primary};
        text-transform: uppercase;
        font-family: ${props => props.theme.font.family.condensed};
        font-weight: ${props => props.theme.font.weight.bold};
        font-size: ${props => props.theme.font.size.lg};
    }
`
 
 const NewsPreview = (props) => {
     const [modal, setModal] = useState(false);
     const toggle = () => setModal(!modal);
 
     const { node } = props.data
     return(
         <ArticleStyled borderOnHover={props.borderOnHover}>
             {/*if we have a youtube video, we print that, otherwise just print the featured image, if there is one*/}
             {node.acfNews.newsYoutube ? (
                 <>
                     <div className="news-video" onClick={toggle} onKeyDown={toggle} role="button" tabIndex={0}>
                        {node.acfNews.featuredImage45 && 
                        node.acfNews.featuredImage45.localFile &&
                        node.acfNews.featuredImage45.localFile.childImageSharp &&
                         <GatsbyImage
                             image={node.acfNews.featuredImage45.localFile.childImageSharp.gatsbyImageData}
                             alt={node.acfNews.featuredImage45.altText}
                         /> 
                        }
                         <ItemBg 
                            className="hoverBg video" 
                            borderOnHover={props.borderOnHover}
                            video
                         >
                            <div className="news-play">
                                <img src={Play} alt="Play" />
                                <span className="sr-only">Play</span>
                            </div>
                         </ItemBg>
                         <div className="news-content">
                             <h2 dangerouslySetInnerHTML={{ __html: node.title }} />
                             <p className="date"><DateFormat date={node.date} /></p>
                             {/* <p className="more text-uppercase">Watch Now</p> */}
                         </div>
                     </div>
                     <VideoOverlay 
                         url={node.acfNews.newsYoutube} 
                         active={modal} 
                         toggleVideoOverlay={toggle}
                     />
                 </>
             ) : (
                 <>
                     {node.acfNews.featuredImage45 && node.acfNews.featuredImage45.localFile &&
                         <Link to={node.uri} className="position-relative">
                            <GatsbyImage
                                image={node.acfNews.featuredImage45.localFile.childImageSharp.gatsbyImageData}
                                alt={node.acfNews.featuredImage45.altText}
                            /> 
                            <ItemBg className="hoverBg">
                                <div>Read More</div>
                            </ItemBg>
                            <div className="news-content">
                                <h2 dangerouslySetInnerHTML={{ __html: node.title }} />
                                <p className="date"><DateFormat date={node.date} /></p>
                                <p className="more text-uppercase d-md-none">Read More</p>
                            </div>
                         </Link>
                     }
                 </>
             )}
         </ArticleStyled>
     )
 }
 
 export default NewsPreview