import React, { useRef, useState } from "react";
import Slider from "react-slick";
import styled, { css } from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import Text from "components/shared/Text";
import { LinkSearchReplace } from "utils/LinkSearchReplace";
import ContainerMax from "components/shared/ContainerMax";
import TextBlockStyles from "components/shared/TextBlockStyles";
import { media } from "utils/Media";
import ArrowIcon from "components/icons/arrrow";

const SliderStyled = styled(Slider)`
  margin-top: -20px;
  position: relative;
  overflow-x: hidden;
  margin: 0 auto;
  .slider-arrow {
    /* left: 2rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 43px 43px;
    width: 45px;
    height: 45px;
    background-color: transparent;
    border: 0;
    position: absolute;
    bottom: 4rem;
    z-index: 50;
    background: #8adaf1; */
    display: flex;
      align-items: center;
      justify-content: center;
      background: #8adaf1;
      border-radius: 50px;
      background-size: 43px 43px;
      width: 45px;
      height: 45px;
      border: none;
      transition: all 0.25s ease-in-out;
    svg {
      width: 50px;
      height: 50px;
    }
  }
  .slider-arrow {

  }
`;
const SlideStyled = styled.div`
  /* min-height: calc(110vh - 90px); */
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #586bb8;
  @media (min-width: 414px) {
    /* min-height: calc(100vh - 90px); */
  }
  @media ${media.md} {
    max-height: 700px;
    /* min-height: calc(70vh - 90px); */
  }
  @media ${media.xl} {
    max-height: 700px;
  }
  .image-mob {
    @media ${media.md} {
      display: none;
    }
  }
  .image-desktop {
    display: none;
    @media ${media.md} {
      display: block;
    }
  }
  .image-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
    @media ${media.xl} {
      .gatsby-image-wrapper {
        width: auto;
        height: auto;
      }
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      content: "";
      z-index: 0;
    }
  }
`;
const SliderInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  height: 85vh;
  max-height: 500px;
  @media ${media.md} {
    height: auto;
    max-height: 750px;
  }
  .wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 4rem 0 9rem 0;
    /* min-height: 80vh; */
    background-color: #586bb8;
    .text {
      max-width: 1000px;
      width: 90%;
      margin: 0 auto;
      color: #fff;
      /* padding: 5rem 0; */
    }
    .sideImage {
      display: none;
    }
    ${(props) =>
      props.backgroundImage &&
      css`
        background-image: url("${props.backgroundImage}");
      `}
    @media ${media.md} {
      min-height: 750px;
      flex-direction: row-reverse;
      align-items: center;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 0 5rem 0;
      .sideImage {
        position: relative;
        z-index: 10;
        display: block;
        width: 200px;
      }
    }
    @media ${media.xl} {
      padding-bottom: 6rem;
    }
  }
  .withImage {
    @media ${media.md} {
      .text {
        width: 50%;
        margin: 0 1.5rem 0 3rem;
      }
    }
    @media ${media.lg} {
      .text {
        width: 60%;
        margin: 0 1.5rem 0 3rem;
      }
    }
  }
`;
const TitleStyles = styled.h2`
  font-family: ${(props) => props.theme.font.family.heading};
  font-style: italic;
  font-weight: ${(props) => props.theme.font.weight.bold};
  color: #ebca44;
  margin: 0;
  font-size: 1.875rem;
  text-transform: uppercase;
  margin-bottom: 1.85rem;
  position: relative;
  z-index: 99;
  @media ${media.md} {
    font-size: 2.5rem;
  }

  @media ${media.lg} {
    font-size: 3.5rem;
  }

  @media ${media.xl} {
    font-size: 4rem;
  }

  @media ${media.xxl} {
    font-size: 4.375rem;
  }
  ${(props) =>
    props.backgroundIsWhite === true &&
    css`
      background: ${(props) => props.theme.colors.titleGradient};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `}
  ${(props) =>
    props.img === true &&
    css`
      color: #ffffff;
    `}
`;
const ButtonTogglesStyles = styled.section`
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  .button-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    @media (min-width: 414px) {
      bottom: 100%;
    }
    @media ${media.md} {
      width: 80%;
      max-width: 1000px;
      margin: 0 auto 0 10% ;
      .next {
        right: 5rem;
      }
    }
    @media ${media.xl} {
      width: 100%;
      max-width: 1000px;
      margin: 0 auto;
      bottom: 0;
      left: 0;
      .next {
        right: 5rem;
      }
    }
    @media ${media.xxl} {
      width: 100%;
      max-width: 1150px;
      margin: 0 auto;
      bottom: 0;
      left: 0;
      .next {
        right: 5rem;
      }
    }
    margin-top: 0.25rem;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #8adaf1;
      border-radius: 50px;
      background-size: 43px 43px;
      width: 45px;
      height: 45px;
      border: none;
      transition: all 0.25s ease-in-out;
      svg {
        width: 20px;
        height: 20px;
        transition: all 0.25s ease-in-out;
        fill: #000000;
      }
      &:hover,
      &:active {
        transform: scale(1.1);
        background-color: ${(props) => props.theme.colors.primary};
        svg {
          fill: #000 !important;
        }
      }
      &:nth-child(1) {
        svg {
          transform: scaleX(-1);
        }
      }
    }
    .not-active {
      opacity: 0.1;
    }
  }
  @media ${media.xl} {
    bottom: 150px;
    left: -2.25rem;
    .button-toggles {
      width: 90%;
    }
  }
  @media ${media.xxl} {
    bottom: 150px;
    left: 2rem;
    right: 0;
    .button-toggles {
      width: 90%;
    }
  }
`;
const SliderNavStyles = styled(Slider)`
  position: absolute;
  bottom: 1rem;
  width: 90%;
  right: 0;
  left: 10%;
  overflow: visible;
  @media (min-width: 414px) {
    bottom: 1.5rem;
  }
  @media ${media.md} {
    width: 80%;
    left: 20%;
  }
  @media ${media.xl} {
    bottom: 2rem;
    left: auto;
    right: 0;

    .slick-track {
      margin-left: auto;
      margin-right: 0;
    }
  }
`;
const SlideNavItem = styled.div`
  @keyframes heartbeat {
    from {
      transform: scale(1);
      transform-origin: center center;
      animation-timing-function: ease-out;
    }
    10% {
      transform: scale(0.91);
      animation-timing-function: ease-in;
    }
    17% {
      transform: scale(0.98);
      animation-timing-function: ease-out;
    }
    33% {
      transform: scale(0.87);
      animation-timing-function: ease-in;
    }
    45% {
      transform: scale(1);
      animation-timing-function: ease-out;
    }
  }
  position: relative;
  padding-top: 1rem;
  /* width: 200px !important; */
  p {
    transition: all 0.25s ease-in-out;
    color: #fff;
  }
  .year {
    padding-left: 6.5px;
  }
  .dot {
    position: absolute;
    background-color: ${(props) => props.theme.colors.white};
    height: 1px;
    width: 100%;
    top: 7px;
    left: 2px;
    transition: all 0.25s ease-in-out;
    &:before,
    &:after {
      content: "";
      position: absolute;
      transition: all 0.25s ease-in-out;
    }
    &:before {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      top: -6px;
      left: 0;
      background-color: ${(props) => props.theme.colors.white};
    }
    &:after {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 2px solid ${(props) => props.theme.colors.yellow};
      background: transparent;
      top: -7px;
      left: -1px;
      opacity: 0;
    }
    @media ${media.lg} {
     width: 120%; 
    }
    @media ${media.xl} {
     width: 170%; 
    }
  }

  .slick-current & {
    span {
      &:after {
        animation: heartbeat 1.5s ease-in-out infinite both;
        opacity: 1;
      }
    }
  }
`;
const SliderNav = ({ years, slider1Nav, setSlider2Nav, white }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    initialSlide: 0,
    slidesToShow: years.length > 4 ? 5 : 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: years.length > 4 ? 5 : 1
        },
      },
      {
        breakpoint: 480,
        settings: {
          // slidesToShow: 3,
          slidesToShow: years.length > 3 ? 3 : 1
        },
      },
    ],
  };
  // console.log( years.length > 3 ? 3 : years.length)
  return (
    <SliderNavStyles
      asNavFor={slider1Nav}
      ref={(slider) => setSlider2Nav(slider)}
      {...settings}
    >
      {years?.map((year, i) => {
        return (
          <SlideNavItem
            key={`sliderNav${i}`}
            white={white ? "true" : undefined}
          >
            <span className="dot" />
            <p white={white ? "true" : undefined}>
              {/* {year.month} */}
              {/* <span className={!year.month ? "noMonth" : "year"}> */}
                {year.year}
              {/* </span> */}
            </p>
          </SlideNavItem>
        );
      })}
    </SliderNavStyles>
  );
};
const ButtonToggles = ({ next, prev, count, years }) => {
  return (
    <ButtonTogglesStyles>
      <div className="button-toggle">
        <button
          type="button"
          onClick={prev}
        >
          <ArrowIcon />
        </button>
        <button
          type="button"
          onClick={next}
          className="next"
        >
          <ArrowIcon />
        </button>
      </div>
    </ButtonTogglesStyles>
  );
};
const Timeline = ({ block }) => {
  const [slider1Nav, setSlider1Nav] = useState(null);
  const [slider2Nav, setSlider2Nav] = useState(null);
  const [count, setCount] = useState(0);
  const { years } = block;
  const sliderRef = useRef();

  const next = (e) => {
      sliderRef.current.slickNext();
  };
  const prev = (e) => {
      sliderRef.current.slickPrev();
  };

  //slider settings
  const settings = {
    dots: false,
    slidesToShow: 1.025,
    slidesToScroll: 1,
    lazyLoad: true,
    infinite: true,
    speed: 1000,
    fade: true,
    adaptiveHeight: false,
    touchMove: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.025,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.025,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <ContainerMax
      maxWidth="1920"
      noPadding
      extraMargin
      className="position-relative whiteGapFix mx-auto"
    >
      <SliderStyled
        {...settings}
        className="mb-3"
        asNavFor={slider2Nav}
        // ref={(slider) => setSlider1Nav(slider)}
        ref={sliderRef}
      >
        {years?.map((year, i) => {
          const {
            mobileBackgroundImage,
            desktopBackgroundImage,
            title,
            text,
            image,
          } = year;
          return (
            <SlideStyled key={i} className="slick-item">
              <SliderInner>
                <div className={image ? "wrapper withImage" : "wrapper"}>
                  <div className="text">
                    <TitleStyles
                      className={`${desktopBackgroundImage && "text-white"}`}
                    >
                      {title}
                    </TitleStyles>
                    <TextBlockStyles $overlay>
                      <Text
                        as="div"
                        dangerouslySetInnerHTML={{
                          __html: LinkSearchReplace(text),
                        }}
                      />
                    </TextBlockStyles>
                  </div>
                  {image && (
                    <div className="sideImage">
                      <GatsbyImage
                        image={image.localFile.childImageSharp.gatsbyImageData}
                        alt=""
                        placeholder="blurred"

                      />
                    </div>
                  )}
                </div>
              </SliderInner>
              {mobileBackgroundImage && (
                <div className="image-mob image-background">
                  <GatsbyImage
                    image={
                      mobileBackgroundImage.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    alt=""
                    placeholder="blurred"

                  />
                </div>
              )}
              {desktopBackgroundImage && (
                <div className="image-desktop image-background">
                  <GatsbyImage
                    image={
                      desktopBackgroundImage.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    alt=""
                    placeholder="blurred"

                  />
                </div>
              )}
            </SlideStyled>
          );
        })}
      </SliderStyled>
      <ButtonToggles
        next={next}
        prev={prev}
        count={count}
        years={years.length}
      />
      <SliderNav
        years={years}
        slider1Nav={slider1Nav}
        setSlider2Nav={setSlider2Nav}
      />
    </ContainerMax>
  );
};
export default Timeline;
