import React, { useEffect, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import ReactPlayer from "react-player"
import styled from "styled-components"
import scrollToElement from "scroll-to-element"
import { Container } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { media } from "utils/Media"
import Button from "components/shared/Button"
import VideoOverlay from "components/shared/VideoOverlay"
import curve from "images/white-curve-1.svg"
import heroLogo from "images/hero-logo.svg"
import Curve from "components/shared/Curve"
import HeroLogo from "components/icons/heroLogo"

const HeroContainer = styled(Container)`
	position: relative;
	padding: 0;
	margin-bottom: 2rem;
	overflow: hidden;

	h1 {
		span {
			position: absolute;
			z-index: 0;
		}
	}

	img {
		pointer-events: none; 
	}
`

const Logo = styled.h1`
	position: absolute;
	top: 35%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
	width: 90%;
	pointer-events: none; 

	@media ${media.md} {
		width: 70%;
		top: 50%;
	}

	@media ${media.lg} {
		width: 40%;
		top: 45%;
	}

	svg {
		width: 100%;
	}
`

const ButtonWrap = styled.div`
	position: absolute;
	z-index: 2;
	bottom: 2rem;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	/* @media ${media.lg} {
		bottom: 4rem;
	} */

	@media ${media.xl} {
		bottom: 4rem;
	}

	svg {
		color: white;
		margin-top: 1rem;
		font-size: 1.75rem;
		max-width: 24px;
		max-height: 28px;
	}

	.nextSection {
		padding: 0;
		border: 0;
		background-color: transparent;
	}
`

const ButtonStyled = styled(Button)`
	width: 100%;
	padding: .75rem;

	@media ${media.md} {
		padding: .5rem;
	}

	@media ${media.lg} {
		padding: .75rem;
	}

	@media ${media.xl} {
		padding: .9rem 1.15rem;
	}
`


const VideoWrap = styled.div`
	position: absolute;
	z-index: 1;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 230%;
	height: 230%;
	display: none;

	@media ${media.lg} {
		width: 120%;
		height: 120%;
	}

	.embed-responsive-item {
		width: 100% !important;
		height: 100% !important;
	}

	iframe {
		pointer-events: none;
	}

	@media ${media.md} {
		display: block;
	}
`

const LandingHero = ({block}) => {
	const [videoActive, setVideoActive] = useState(false)
	const [isBackgroundVideoPlaying, setIsBackgroundVideoPlaying] = useState(false)

	useEffect(() => {
		if (window?.innerWidth > 767) {
			setIsBackgroundVideoPlaying(true)
		}
	}, [])

	const toggleVideo = () => {
		setVideoActive(!videoActive)
	}

	const scrollToBottom = () => {
		const header = document.getElementById('siteHeader')
		let headerHeight = 0
		if (header) {
			headerHeight = header.offsetHeight
		}
		scrollToElement("#hero-bottom", {
			offset: - headerHeight,
			duration: 500
		})
	}

	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	const title = data?.site?.siteMetadata?.title

	return(
		<React.Fragment>
			<HeroContainer fluid>
				{block.mobileImage &&
					<GatsbyImage 
						image={block.mobileImage.localFile.childImageSharp.gatsbyImageData} 
						alt={block.mobileImage.altText} 
						className="d-md-none"
					/>
				}
				{block.tabletImage &&
					<GatsbyImage 
						image={block.tabletImage.localFile.childImageSharp.gatsbyImageData} 
						alt={block.tabletImage.altText}
						className="d-none d-md-block d-lg-none"
					/>
				}
				{block.desktopImage &&
					<GatsbyImage 
						image={block.desktopImage.localFile.childImageSharp.gatsbyImageData} 
						alt={block.desktopImage.altText}
						className="d-none d-lg-block"
					/>
				}

				{block.backgroundVideo &&
					<VideoWrap className="embed-responsive embed-responsive-16by9 d-none d-md-block">
						<ReactPlayer
							url={block.backgroundVideo}
							playing={isBackgroundVideoPlaying}
							volume={0}
							muted={true}
							controls={false}
							loop={true}
							className="embed-responsive-item"
						/>
					</VideoWrap>
				}

				<Logo>
					{/* <img src={heroLogo} alt={title} width="100%" /> */}
					<HeroLogo />
				</Logo>
				
				<ButtonWrap>
					{block.trailer && block.trailerButtonText &&
						<ButtonStyled as="button" color="white" onClick={toggleVideo}>
							{block.trailerButtonText}
						</ButtonStyled>
					}
					<button className="nextSection" onClick={scrollToBottom}>
						<FontAwesomeIcon icon={faChevronDown} bounce />
						<span className="sr-only">Next section</span>
					</button>
				</ButtonWrap>

				<Curve image={curve} />
			</HeroContainer>
			{block.trailer && 
				<VideoOverlay 
					url={block.trailer} 
					active={videoActive} 
					toggleVideoOverlay={(e) => toggleVideo(e)}
				/>
			}
			<div id="hero-bottom"></div>
		</React.Fragment>
	)
}

export default LandingHero