import styled, { css } from "styled-components"
import { media } from "utils/Media"

const DropdownToggle = styled.button`
    position: relative;
    z-index: 2;
    width: 100%;
    border: 0;
    padding: .75rem;
    text-align: left;
    height: 100%;
    background-color: white;
    color: ${props => props.theme.colors.black};
    font-weight: ${props => props.theme.font.weight.bold};
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;

    @media ${media.lg} {
        padding: .75rem .5rem;
    }

    @media ${media.xl} {
        padding: .75rem;
    }

    ${props => props.imgIcon && css`
        padding-left: calc(1rem + 35px);

        @media ${media.lg} {
            padding-left: .75rem;
        }

        @media ${media.xl} {
            padding-left: calc(1rem + 35px);
        }
    `}

    .icon {
        max-height: 14px;
        margin-left: .5rem;
        position: relative;
    }

    .imgIcon {
        position: absolute;
        left: .5rem;
        top: 50%;
        transform: translateY(-50%);
        max-height: 80%;

        @media ${media.lg} {
            display: none;
        }

        @media ${media.xl} {
            display: block;
        }
    }

    .filter-count {
        background-color: rgba(244, 53, 129, .2);
        padding: .25rem .5rem;
        line-height: 1;
        color: black;
        margin-right: 1rem;
    }

    &.active {
        color: ${props => props.theme.colors.black};
        background-color: ${props => props.theme.colors.white};

        .icon {
            transform: rotate(180deg);
        }
    }
`

export default DropdownToggle