import React from "react"
import { Col } from "reactstrap"
import styled, { css } from "styled-components"
import { media } from "utils/Media"
import { GatsbyImage } from "gatsby-plugin-image"
import ContainerMax from "components/shared/ContainerMax"
import Text from "components/shared/Text"
import WPLink from "components/shared/WPLink"
import snowflake from "images/snowflake-partners.svg"

const Wrap = styled.div`
    position: relative;

    ${props => props.background === "blue" && css`
        background-color: ${props => props.theme.colors.midBlue};
    `}
`

const SectionWrap = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    h3 {
        color: ${props => props.theme.colors.secondary};

        ${props => props.background === "blue" && css`
            color: white;
        `}
    }
`

const ContainerMaxStyled = styled(ContainerMax)`
    position: relative;
    z-index: 2;
`

const Item = styled.div`
    width: 100%;
    padding: 1.5rem;

    @media ${media.sm} {
        max-width: 50%;
    }

    @media ${media.md} {
        max-width: 25%;
    }

    .text {
        font-family: ${props => props.theme.font.family.condensed};
        font-weight: ${props => props.theme.font.weight.regular};
        font-size: ${props => props.theme.font.size.md};

        p {
            font-size: ${props => props.theme.font.size.md};
        }
        
        ${props => props.background === "blue" && css`
            color: white;
        `}
    }
`

const SnowflakeStyled = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    width: 100%;

    @media ${media.xl} {
        display: block;
    }
`

const Operators = (props) => {
    const { block } = props
    return (
        <>
        {block.sections && block.sections.map((section, i) => {
            return (
                <Wrap background={section.background} key={i}>
                    {section.background === "white" &&
                        <SnowflakeStyled src={snowflake} alt="" />
                    }
                    <ContainerMaxStyled maxWidth="1300" className="py-4">
                        <SectionWrap background={section.background}>
                            <Col xs={12} className="text-center">
                                <Text as="h3" condensed uppercase className="pb-3">{section.title}</Text>
                            </Col>
                            {section.logos.map((logo, i) => {
                                return (
                                    <Item xs={4} md={3} className="text-center" key={i} background={section.background}>
                                        {logo.url !== null ? (
                                            <a href={logo.url} target="_blank" rel="noopener noreferrer" aria-label="Opens new window">
                                                <GatsbyImage 
                                                    image={logo.image.localFile.childImageSharp.gatsbyImageData} 
                                                    alt={logo.image.altText} 
                                                />
                                            </a>
                                        ) : (
                                            <GatsbyImage 
                                                image={logo.image.localFile.childImageSharp.gatsbyImageData} 
                                                alt={logo.image.altText} 
                                            />
                                        )}
                                        {logo.text &&
                                            <div className="text pt-4" dangerouslySetInnerHTML={{ __html: logo.text }} />
                                        }
                                        {logo.button &&
                                            <div className="mt-4">
                                                <WPLink url={logo.button.url} target={logo.button.target} button color="secondary">
                                                    {logo.button.title}
                                                </WPLink>
                                            </div>
                                        }
                                    </Item>
                                )
                            })}
                        </SectionWrap>
                    </ContainerMaxStyled>
                </Wrap>
            )
        })}
        </>
    )
}

export default Operators