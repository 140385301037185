import React, { Component } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col, UncontrolledCollapse } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import ContainerMax from "components/shared/ContainerMax"
import { LinkSearchReplace } from "utils/LinkSearchReplace"
import { media } from "utils/Media"
import Text from "components/shared/Text"
import snowflakeImg from "images/snowflake-map.svg"

const FAQButton = styled.button`
    display: block;
    width: 100%;
    background-color: transparent;
    padding: 1rem 1rem 1rem 0;
    border: 0;
    border-top: 1px solid #c4c4c4;
    text-align: left;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.font.size.xl};
    font-family: ${props => props.theme.font.family.condensed};
    font-weight: ${props => props.theme.font.weight.bold};
    text-transform: uppercase;
    
    &:focus {
        outline: 0;
    }

    .icon {
        position: absolute;
        right: 1rem;
        color: ${props => props.theme.colors.secondary};
        transform: rotate(0);

        svg {
            max-width: 12px;
        }
    }

    &.active {
        .icon {
            transform: rotate(180deg);
        }
    }
`

const SnowflakeStyled = styled.img`
    position: absolute;
    left: 0;
    top: 5rem;
    display: none;

    @media ${media.xl} {
        display: block;
    }
`

class MapFAQ extends Component {
    toggleClass(id) {
        document.getElementById(id).classList.toggle("active")
    }

    render() {
        const { block } = this.props
        const { title, faqs, map } = block

        return(
            <Container fluid className="position-relative px-0">
                <ContainerMax className="py-4 py-xl-5" maxWidth="1300">
                    <SnowflakeStyled src={snowflakeImg} alt="" />
                    <Row>
                        <Col xs={12}>
                            {title &&
                                <Text as="h3" uppercase secondary heading italic className="pb-4 text-center text-md-left">
                                    {title}
                                </Text>
                            }
                        </Col>
                        <Col xs={12} md={6}>
                            {faqs && faqs.map((faq, faqId) => {
                                return(
                                    <Row key={faqId}>
                                        <Col sm="12">
                                            <FAQButton id={`faq${faqId}`} onClick={() => this.toggleClass(`faq${faqId}`)} onTouchStart={() => this.toggleClass(`faq${faqId}`)}>
                                                {faq.icon &&
                                                    <GatsbyImage
                                                        image={faq.icon.localFile.childImageSharp.gatsbyImageData} 
                                                        alt={faq.icon.altText} 
                                                        className="mr-2"
                                                    />
                                                }
                                                {faq.question}
                                                <FontAwesomeIcon icon={faAngleDown} className="icon" />
                                            </FAQButton>
                                            <UncontrolledCollapse toggler={`#faq${faqId}`}>
                                                <div dangerouslySetInnerHTML={{ __html: LinkSearchReplace(faq.answer) }} className="pb-3"/>
                                            </UncontrolledCollapse>
                                        </Col>
                                    </Row>
                                )
                            })}
                        </Col>
                        <Col xs={12} md={6}>
                            {map &&
                                <GatsbyImage
                                    image={map.localFile.childImageSharp.gatsbyImageData} 
                                    alt={map.altText}
                                />
                            }
                        </Col>
                    </Row>
                </ContainerMax>
            </Container>
        )
    }
}

export default MapFAQ