import React from "react"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { media } from "utils/Media"
import { LinkSearchReplace } from "utils/LinkSearchReplace"
import { Container, Row, Col, UncontrolledCollapse } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import TextBlockStyles from "components/shared/TextBlockStyles"

const FAQButton = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    background-color: transparent;
    padding: 1rem 1rem 1rem 0;
    border: 0;
    border-top: 1px solid #c4c4c4;
    text-align: left;
    color: ${props => props.theme.colors.secondary};
    font-size: ${props => props.theme.font.size.xl};
    font-family: ${props => props.theme.font.family.condensed};
    font-weight: ${props => props.theme.font.weight.bold};
    text-transform: uppercase;
    
    &:focus {
        outline: 0;
    }

    .icon {
        position: absolute;
        right: 1rem;
        color: ${props => props.theme.colors.secondary};
        transform: rotate(0);

        svg {
            max-width: 12px;
        }
    }

    &.active {
        .icon {
            transform: rotate(180deg);
        }
    }
`

const TextStyled = styled(TextBlockStyles)`
  border-top: 1px solid #c4c4c4;
  padding-top: 1rem;

  ${props => props.last && css`
    border-bottom: 1px solid #c4c4c4;
  `}
`

const DropdownWrap = styled.div`
  ${props => props.last && css`
    @media ${media.md} {
      border-bottom: 1px solid #c4c4c4;
    }
  `}
`

const Dropdown = (props) => {
  return(
    <DropdownWrap last={props.last}>
      {/* <FAQButton id={props.id} onClick={() => this.toggleClass(`faq${faqId}`)} onTouchStart={() => this.toggleClass(`faq${faqId}`)}> */}
      <FAQButton id={props.id}>
        {props.data.icon &&
          <GatsbyImage
            image={props.data.icon.localFile.childImageSharp.gatsbyImageData} 
            alt={props.data.icon.altText} 
            className="mr-2"
          />
        }
        {props.data.title}
        <FontAwesomeIcon icon={faAngleDown} className="icon" />
      </FAQButton>
      <UncontrolledCollapse toggler={`#${props.id}`}>
        <TextStyled dangerouslySetInnerHTML={{ __html: LinkSearchReplace(props.data.text) }} className="pb-3"  />
      </UncontrolledCollapse>
    </DropdownWrap>
  )
}

const TransportDropdowns = (props) => {
  const { dropdown } = props.block
  const half = Math.ceil(dropdown.length / 2);
  const dropdowns1 = dropdown.slice(0, half)
  const dropdowns2 = dropdown.slice(-half)

  return (
    <Container className="py-4 py-xl-5">
      <Row>
        <Col xs={12} md={6}>
          {dropdowns1 && dropdowns1.map((d, i) => {
            return <Dropdown key={i} id={`faq1${i}`} data={d} last={i === dropdowns1.length-1} />
          })}
        </Col>
        <Col xs={12} md={6}>
          {dropdowns2 && dropdowns2.map((d, i) => {
            return <Dropdown key={i} id={`faq2${i}`} data={d} last={i === dropdowns2.length-1} />
          })}
        </Col>
      </Row>
    </Container>
  )
}

export default TransportDropdowns