import styled, { css } from "styled-components"
import { media } from "utils/Media"

const FilterBar = styled.div`
    background-color: ${props => props.theme.colors.white};
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 11;
    padding: 1rem;
    overflow-y: auto;
    z-index: 101;
    padding-top: 60px;

    ${props => props.displayMobile && css`
        position: relative;
        padding: 0;
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        /* justify-content: center; */
        overflow-y: visible;

        @media ${media.lg} {
            padding: 1rem;
        }
    `}

    @media ${media.lg} {
        position: relative;
        padding-top: 1rem;
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        background-color: ${props => props.theme.colors.primary};
        overflow-y: visible;
    }

    .filter-buttons {
        a {
            width: calc(50% - .5rem);
            @media ${media.lg} {
                width: 100%;
            }
        }
    }

    .filter-close {
        border: 0;
        background: transparent;
        font-size: 1.5rem;
        position: absolute;
        right: 1rem;
        top: .5rem;
        z-index: 2;
        color: black;

        @media ${media.lg} {
            display: none;
        }
    }

    .filter-title {
        display: none;
        margin-right: 1rem;
        font-weight: ${props => props.theme.font.weight.bold};

        @media ${media.lg} {
            display: block;
        }
    }

    &.active {
        display: block;

        @media ${media.lg} {
            display: flex;
        }
    }
`

export default FilterBar