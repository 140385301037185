import styled, { css } from "styled-components"
import { media } from "utils/Media"

const DropdownMenu = styled.ul`
    z-index: 1;
    padding: 0;
    margin: 0;
    padding-bottom: .5rem;
    background-color: white;
    width: 100%;
    list-style: none;
    display: none;

    @media ${media.lg} {
        position: absolute;
        top: calc(100% - 8px);
        padding-top: .5rem;
    }

    ${props => props.type === "date" && css`
        padding: .5rem;
    `}

    &.active {
        display: block;
    }
`

export default DropdownMenu