import React from "react"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { media } from "utils/Media"
import ContainerMax from "components/shared/ContainerMax"
import Text from "components/shared/Text"
import WPLink from "components/shared/WPLink"

const ContainerStyled = styled(Container)`
    background-color: ${props => props.theme.colors.midBlue};

    h3 {
        font-size: ${props => props.theme.font.h2.size};
        @media ${media.lg} {
            font-size: ${props => props.theme.font.h1.size};
        }
    }
`

const GettingHere = (props) => {
    const { block } = props
    const { image, title, blocks, link } = block

    return(
        <ContainerStyled fluid className="py-5">
            <Row>
                <ContainerMax maxWidth="1600">
                    <Row>
                        <Col lg={6} className="text-center text-md-left">
                            {block.image &&
                                <GatsbyImage
                                    image={image.localFile.childImageSharp.gatsbyImageData}
                                    alt={image.altText}
                                    className="mb-4 mb-lg-0"
                                />
                            }
                        </Col>
                        <Col lg={6}>
                            {title && 
                                <Text as="h3" uppercase white heading italic className="pb-4 text-center text-md-left">
                                    {title}
                                </Text>
                            }
                            <Row>
                                {blocks && blocks.map((block, i) => {
                                    return(
                                        <Col md={6} key={i} className="pb-4">
                                            <Row>
                                                {block.icon &&
                                                    <Col xs="auto" md={12}>
                                                        <GatsbyImage
                                                            image={block.icon.localFile.childImageSharp.gatsbyImageData}
                                                            alt={block.icon.altText}
                                                            className="mb-2"
                                                        />
                                                    </Col>
                                                }
                                                <Col xs={9} sm={10} md={12}>
                                                    {block.title &&
                                                        <Text as="h4" uppercase white condensed xl className="mb-2">
                                                            {block.title}
                                                        </Text>
                                                    }
                                                    {block.text &&
                                                        <Text as="div" white dangerouslySetInnerHTML={{ __html: block.text }} />
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                    </Row>
                    {link &&
                    <Row className="justify-content-center pt-3 pt-md-5">
                        <Col xs="auto">
                            <WPLink
                                url={link.url} 
                                target={link.target}
                                button
                                color="white">
                                    {link.title}
                            </WPLink>
                        </Col>
                    </Row>
                    }
                </ContainerMax>
            </Row>
        </ContainerStyled>
    )
}

export default GettingHere