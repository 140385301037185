import React, { useState } from "react"
import classNames from "classnames"
import { Container, Row, Col } from "reactstrap"
import { media } from "utils/Media"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import ContainerMax from "components/shared/ContainerMax"
import Button from "components/shared/Button"
import ListingItemStyled from "./ThingsToDoListing/ListingItemStyled"
import WPLink from "components/shared/WPLink"
import SnowflakeBg from "components/shared/SnowflakeBg"
import snowflakeImg from "images/snowflake-food-drink.svg"

const SnowflakeBgStyled = styled(SnowflakeBg)`
  top: 2rem;
`

const ListingItem = styled(ListingItemStyled)`
  @media ${media.md} {
    padding-bottom: calc(46px + 3rem);
  }

  @media ${media.lg} {
    padding-bottom: calc(46px + 1rem);
  }
`

const ThreeColumnsBlock = (props) => {
  const { blocks } = props.block


  const Block = ({data}) => {
    const [descriptionActive, setDescriptionActive] = useState(false)

    return(
      <Col 
        sm={12}
        md={4}
        className="mb-sm-4 mb-xl-5"
      >
        <ListingItem 
          featured={false}
          threeColumnBlock={true}
          noPadding={false}
        >
          <Row>
            <Col 
              xs={4} 
              sm={4}
              md={12}
            >
              <GatsbyImage
                image={data.image.localFile.childImageSharp.gatsbyImageData}
                alt={data.image.altText}
              /> 
            </Col>
            <Col 
              xs={8} 
              md={12}
            >
              <Row>
                <Col xs={12} className="pt-3 pt-sm-0">
                  {/* main title */}
                  <h4 className="pb-2">{data.title}</h4>
                </Col>
              </Row>
              {data.text &&
                <div dangerouslySetInnerHTML={{ __html: data.text }} className="d-none d-sm-block pb-2" />
              }
              <div className={classNames({
                "buttons d-flex justify-content-between d-md-none": true,
              })}>
                {data.text &&
                  <Button
                    className={classNames({
                      "expand-button d-sm-none": true,
                      active: descriptionActive
                    })}
                    onClick={() => setDescriptionActive(!descriptionActive)}
                  >
                    <span className="sr-only">Toggle description</span>
                  </Button>
                }
                {data.link2 &&
                  <WPLink url={data.link2.url} target={data.link2.target} button color="secondary">
                    {data.link2.title}
                  </WPLink>
                }
                <div className="buttons d-none d-sm-block d-md-none">
                  {data.link1 &&
                    <WPLink url={data.link1.url} target={data.link1.target} button color="orange" className="mr-3">
                      {data.link1.title}
                    </WPLink>
                  }
                  {/* {data.link2 &&
                    <WPLink url={data.link2.url} target={data.link2.target} button color="secondary">
                      {data.link2.title}
                    </WPLink>
                  } */}
                </div>
              </div>
            </Col>
          </Row>
          <div className="buttons d-none d-md-flex flex-wrap">
            {data.link1 &&
              <WPLink url={data.link1.url} target={data.link1.target} button color="orange" className="mr-3 mt-2">
                {data.link1.title}
              </WPLink>
            }
            {data.link2 &&
              <WPLink url={data.link2.url} target={data.link2.target} button color="secondary" className="mt-2">
                {data.link2.title}
              </WPLink>
            }
          </div>
          
          {descriptionActive && 
            <div className="d-md-none">
              {data.text &&
                <div 
                  dangerouslySetInnerHTML={{ __html: data.text }} 
                  className="py-3"
                />
              }
              {data.link1 &&
                <WPLink url={data.link1.url} target={data.link1.target} button color="orange" className="mr-3">
                  {data.link1.title}
                </WPLink>
              }
            </div>
          }
        </ListingItem>
      </Col>
    )
  }

  return(
    <Container fluid className="position-relative py-4 px-0">
      <ContainerMax className="pb-md-4">
        <SnowflakeBgStyled src={snowflakeImg} alt="" />
        <Row>
          {blocks?.map((b, i) => {
            // console.log(b)
            return(
              <Block data={b} key={`threecolblock${i}`} />
            )
          })}
        </Row>
      </ContainerMax>
    </Container>
  )
}

export default ThreeColumnsBlock