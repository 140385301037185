import React from "react"
import { Col } from "reactstrap"
import styled, { css } from "styled-components"
import { media } from "utils/Media"
import { GatsbyImage } from "gatsby-plugin-image"
import ContainerMax from "components/shared/ContainerMax"
import Text from "components/shared/Text"
import snowflake from "images/snowflake-partners.svg"

const Wrap = styled.div`
    position: relative;

    ${props => props.background === "blue" && css`
        background-color: ${props => props.theme.colors.midBlue};
    `}
`

const SectionWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    h3 {
        color: ${props => props.theme.colors.secondary};

        ${props => props.background === "blue" && css`
            color: white;
        `}
    }
`

const Item = styled.div`
    max-width: 50%;
    padding: 1.5rem;

    @media ${media.sm} {
        max-width: 33%;
    }

    @media ${media.md} {
        max-width: 20%;
    }
`

const SnowflakeStyled = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    width: 100%;

    @media ${media.xl} {
        display: block;
    }
`

const Partners = (props) => {
    const { block } = props
    return (
        <>
        {block.sections.map((section, i) => {
            return (
                <Wrap background={section.background} key={i}>
                    {section.background === "white" &&
                        <SnowflakeStyled src={snowflake} alt="" />
                    }
                    <ContainerMax maxWidth="1300" className="py-4">
                        <SectionWrap background={section.background}>
                            <Col xs={12} className="text-center">
                                <Text as="h3" condensed uppercase className="pb-3">{section.title}</Text>
                            </Col>
                            {section.logos.map((logo, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <Item xs={4} md={3}>
                                            {logo.url !== null ? (
                                                <a href={logo.url} target="_blank" rel="noopener noreferrer" aria-label="Opens new window">
                                                    <GatsbyImage 
                                                        image={logo.image.localFile.childImageSharp.gatsbyImageData} 
                                                        alt={logo.image.altText} 
                                                    />
                                                </a>
                                            ) : (
                                                <GatsbyImage 
                                                    image={logo.image.localFile.childImageSharp.gatsbyImageData} 
                                                    alt={logo.image.altText} 
                                                />
                                            )}
                                        </Item>
                                        {logo.lineBreak &&
                                            <div className="w-100"></div>
                                        }
                                    </React.Fragment>
                                )
                            })}
                        </SectionWrap>
                    </ContainerMax>
                </Wrap>
            )
        })}
        </>
    )
}

export default Partners