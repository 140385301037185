import styled, { css } from "styled-components"
import { media } from "utils/Media"

const DropdownWrap = styled.div`
    min-width: 140px;
    width: 100%;
    text-transform: uppercase;

    @media ${media.lg} {
        min-width: 150px;
        width: auto;
        margin-right: 1rem;
    }

    ${props => props.last && css`
        @media ${media.lg} {
            margin-right: 0;
        }
    `}
`

export default DropdownWrap