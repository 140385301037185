import React from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { media } from "utils/Media"
import { LinkSearchReplace } from "utils/LinkSearchReplace"
import ContainerMax from "components/shared/ContainerMax"
import Text from "components/shared/Text"
import TextBlockStyles from "components/shared/TextBlockStyles"
import WPLink from "components/shared/WPLink"

const SidebarWrap = styled.div`
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 1rem;
    margin-top: 2rem;

    @media ${media.md} {
        margin: 0;
    }

    @media ${media.lg} {
        max-width: 330px;
    }

    h1, h2, h3, h4, h5 {
        font-family: ${props => props.theme.font.family.condensed};
        font-weight: ${props => props.theme.font.weight.bold};
    }
`

const Border = styled.div`
  border: 3px solid ${props => props.theme.colors.primary};
  padding: 2rem;
  border-radius: 4px;
`

const TextWithSidebar = (props) => {
    const { block } = props

    return (
        <ContainerMax className="pb-4" maxWidth={(block.width === null || block.width) === "thin" ? 1300 : 1600 }>
            <Row className="justify-content-between">
                <Col xs={12} md={8}>
                    <Row>
                        {block.icon &&
                        <Col xl={1} className="d-none d-xl-block date-share mb-3">
                            <Row>
                                <Col xs={6} md={12}>
                                    <GatsbyImage
                                        image={block.icon.localFile.childImageSharp.gatsbyImageData}
                                        alt={block.icon.altText}
                                        className="mb-2"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        }
                        <Col xs={block.icon ? 11 : 12} md={(block.width === null || block.width) === "thin" ? 12 : 8}>
                            {block.title &&
                                <Text as="h2" title className="mb-3">{block.title}</Text>
                            }
                            {block.text &&
                                <TextBlockStyles>
                                    <div dangerouslySetInnerHTML={{ __html: LinkSearchReplace(block.text) }} />
                                </TextBlockStyles>
                            }
                        </Col>
                    </Row>
     
                    {(block.borderedText.title || block.borderedText.text) &&
                        <Border className="my-4">
                            <Text as="h2" title className="mb-3">{block.borderedText.title}</Text>
                            <TextBlockStyles dangerouslySetInnerHTML={{ __html: LinkSearchReplace(block.borderedText.text) }} />
                        </Border>
                    }
                </Col>
                
                <Col xs={12} md={4} xl={0}>
                    {(block.textSidebar || block.sidebarLink) &&
                        <SidebarWrap>
                            {block.sidebarTitle &&
                                <Text secondary uppercase size="1.5" condensed dangerouslySetInnerHTML={{ __html: block.sidebarTitle }} />
                            }
                            {block.textSidebar &&
                                <Text as="div" dangerouslySetInnerHTML={{ __html: LinkSearchReplace(block.textSidebar) }} />
                            }
                            {block.sidebarLink &&
                                <WPLink url={block.sidebarLink.url} target={block.sidebarLink.target} button>
                                    {block.sidebarLink.title}
                                </WPLink>
                            }
                        </SidebarWrap>
                    }
                </Col>
            </Row>
        </ContainerMax>
    )
}

export default TextWithSidebar