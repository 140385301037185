import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import { Row, Col } from "reactstrap"
import ContainerMax from "components/shared/ContainerMax"
import ContentMax from "components/shared/ContentMax"
import ListingItem from "./ListingItem"
import ListingFilterBar from "./ListingFilterBar"

class Listing extends Component {
	constructor(props) {
		super(props)
		this.state = {
			listingData: [],
			filters1: [],
			filter1Arr: [],
			filters2: [],
			filter2Arr: [],
			filters3: [],
			filter3Arr: []
		}

		this.setFilters = this.setFilters.bind(this)
		this.resetFilters = this.resetFilters.bind(this)
	}

	componentDidMount() {
		const { 
			listing, 
			filter1, 
			filter2, 
			filter3, 
			filter1Name,
			filter2Name,
			filter3Name
		} = this.props.block

		let listingData = this.props.data[listing]
		let filter1Arr = []
		let filter2Arr = []
		let filter3Arr = []
		let filterTemp1 = []
		let filterTemp2 = []
		let filterTemp3 = []
		filterTemp1["name"] = filter1Name ? filter1Name : filter1
		filterTemp2["name"] = filter2Name ? filter2Name : filter2
		filterTemp3["name"] = filter3Name ? filter3Name : filter3

		// // Filter data by polylang language (we only want to see data for current lang)
		// listingData = listingData && listingData.edges.filter(({node}) => node.language.code === this.context.currentLanguage.code)

		// create unique array of filters that have been tagged for the filter bar
		// its unique as the key is the databaseId so any dubs would get overwritten
		// also prevents any unused tags from being displayed
		listingData?.edges.forEach(({node}) => {
			if (filter1) node[filter1] && node[filter1].nodes && node[filter1].nodes.forEach(node => { filter1Arr[node.databaseId] = node })
			if (filter2) node[filter2] && node[filter2].nodes && node[filter2].nodes.forEach(node => { filter2Arr[node.databaseId] = node })
			if (filter3) node[filter3] && node[filter3].nodes && node[filter3].nodes.forEach(node => { filter3Arr[node.databaseId] = node })
		})

		// Get correct order of categories
		this.props.data[filter1] && this.props.data[filter1].edges.forEach(({node}) => {
			if (filter1Arr[node.databaseId]) filterTemp1.push(filter1Arr[node.databaseId])
		})

		this.props.data[filter2] && this.props.data[filter2].edges.forEach(({node}) => {
			if (filter2Arr[node.databaseId]) filterTemp2.push(filter2Arr[node.databaseId])
		})

		this.props.data[filter3] && this.props.data[filter3].edges.forEach(({node}) => {
			if (filter3Arr[node.databaseId]) filterTemp3.push(filter3Arr[node.databaseId])
		})

		filter1Arr = filterTemp1
		filter2Arr = filterTemp2
		filter3Arr = filterTemp3
	
		// listingData array in state wont change going forwards
		this.setState({
			listingData: listingData.edges,
			filter1Arr,
			filter2Arr,
			filter3Arr
		})
	}

	setFilters(selectedFilters, filter) {
		if (filter === 1) {
			this.setState({
				filters1: selectedFilters
			})
		} else if (filter === 2) {
			this.setState({
				filters2: selectedFilters
			})
		} else {
			this.setState({
				filters3: selectedFilters
			})
		}
	}

	resetFilters() {
		this.setState({
			filters1: [],
			filters2: [],
			filters3: []
		})
	}

	render() {
		const { 
			filter1, 
			filter2,
			filter3,
			filter1Label,
			filter2Label,
			filter3Label
		} = this.props.block
		
		let { 
			listingData, 
			filters1, 
			filters2, 
			filters3, 
			filter1Arr, 
			filter2Arr,
			filter3Arr
		} = this.state

		//TODO: Filter listingData here
		// Dont forget all queries below!

		// filters1 filter
		if (filters1.length > 0) {
			listingData = listingData && listingData.filter(({node}) => {
				const nodeTags = node[filter1] && node[filter1].nodes
				return nodeTags && nodeTags.find(node => filters1.includes(node.databaseId))
			})
		}

		// filters2 filter
		if (filters2.length > 0) {
			listingData = listingData && listingData.filter(({node}) => {
				const nodeTags = node[filter2] && node[filter2].nodes
				return nodeTags && nodeTags.find(node => filters2.includes(node.databaseId))
			})
		}

		// filters3 filter
		if (filters3.length > 0) {
			listingData = listingData && listingData.filter(({node}) => {
				const nodeTags = node[filter3] && node[filter3].nodes
				return nodeTags && nodeTags.find(node => filters3.includes(node.databaseId))
			})
		}

		const items = listingData && listingData.map(({node}, i) => {
			return(
				<Col key={i} xs={6} md={4} lg={3} className="mb-3">
					<ListingItem 
						data={node}
					/>
				</Col>
			)
		})

		return(  
			<ContainerMax className="py-4">
				<ContentMax>
					<ListingFilterBar 
						filter1={filter1 && filter1Arr}
						filter2={filter2 && filter2Arr} 
						filter3={filter3 && filter3Arr} 
						filter1Label={filter1Label}
						filter2Label={filter2Label} 
						filter3Label={filter3Label} 
						selectedFilters1={filters1} 
						selectedFilters2={filters2}
						selectedFilters3={filters3}
						setFilters={this.setFilters} 
						resetFilters={this.resetFilters} 
					/>
						<Row>
							{items}
						</Row>
				</ContentMax>
			</ContainerMax>
		)
	}
}

const ListingExport = (props) => (
		<StaticQuery
			query={graphql`
				query {
					streetfood: allWpStreetfood(limit: 1000, filter: {status: {eq: "publish"}}) {
						edges {
							node {
								title
								uri
								slug
								acfListingPageSettings {
									type
									featuredImage45 {
										altText
										localFile {
											childImageSharp {
												gatsbyImageData(width: 460, quality: 85, placeholder: NONE, layout: CONSTRAINED)
											}
										}
									}
									logo {
										altText
										localFile {
											childImageSharp {
												gatsbyImageData(width: 200, quality: 85, placeholder: NONE, layout: CONSTRAINED)
											}
										}
									}
									popupText
									popupCtaLink {
										url
										title
										target
									}
									popupFacebook
									popupFacebookText
									popupTwitter
									popupTwitterText
									popupInstagram
									popupInstagramText
									popupWebsiteLink
									galleryOrSingleImage
									popupGallery {
										image {
											altText
											caption
											localFile {
												childImageSharp {
													gatsbyImageData(width: 698, quality: 85, placeholder: NONE, layout: CONSTRAINED)
												}
											}
										}
									}
									popupSingleImage {
										altText
										caption
										localFile {
											childImageSharp {
												gatsbyImageData(width: 698, quality: 85, placeholder: NONE, layout: CONSTRAINED)
											}
										}
									}
								}
								acfQuickFacts {
									quickFactsTitle
									quickFactsText
								}
								sfDiets {
									nodes {
										databaseId
										name
									}
								}
								sfOccasions {
									nodes {
										databaseId
										name
									}
								}
								sfCuisines {
									nodes {
										databaseId
										name
									}
								}
								sfFoodtype {
									nodes {
										databaseId
										name
										acfFoodType {
											colour
											backgroundColour
										}
									}
								}
							}
						}
					}
					sfDiets: allWpSfDiet {
						edges {
							node {
								databaseId
								name
							}
						}
					}
					sfOccasions: allWpSfOccasion {
						edges {
							node {
								databaseId
								name
							}
						}
					}
					sfCuisines: allWpSfCuisine {
						edges {
							node {
								databaseId
								name
							}
						}
					}
				}
			`}
		render={data => {
			return (
				<Listing 
					block={props.block}
					data={data} 
				/>
			)
		}}
	/>
)

export default ListingExport