import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import ContainerMax from "components/shared/ContainerMax" 
import WPLink from "components/shared/WPLink" 

const Image = (props) => {
  const { image, link } = props.block
  return (
    <ContainerMax className="text-center py-4">
      {link ? (
        <WPLink url={link.url} target={link.target}>
          <GatsbyImage 
            image={image.localFile.childImageSharp.gatsbyImageData} 
            alt={image.altText} 
          />
        </WPLink>
      ) : (
        <GatsbyImage 
          image={image.localFile.childImageSharp.gatsbyImageData} 
          alt={image.altText} 
        />
      )}
    </ContainerMax>
  )
}

export default Image